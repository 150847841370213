@import "branding";
@import "variables.scss";
@import "pdfviewer.scss";
@import "typography.scss";
@import "src/styles/variables.scss";

body {
  font-family: $font-family;
  color: $body-black;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

html,
body,
#root,
#root>div:not(.Toastify),
#root>div:not(.Toastify) > div {
  margin: 0;
  height: 100%;
  font-family: $font-family;
}

* {
  box-sizing: border-box;
}

.container-wrapper {
  height: calc(100% - 110px);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

button {
  cursor: pointer;
}

hr {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: $theme-Sapphire;
}

.popover {
  font-family: $font-family;
}

div.footer {
  min-width: unset;
}

.btn-link{
  color: $sapphire;
}
