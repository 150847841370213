@import "src/styles/variables.scss";
@media print {
  .print-header {
    background-color: white;
    height: unset;
    font-weight: 400;
    width: 100%;
    span {
      border-bottom: 1px solid $gray-200;
      padding: 0px 0px 20px 30px;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .printable-body {
    margin: 16px 0px 35px 0px;
    .file-count-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
      span {
        display: inline;
      }
    }
    .print-list-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      word-wrap: break-word;
      &:not(:first-child) {
        margin-top: 6px;
      }
      span {
        margin-left: 8px;
        width: 350px;
      }
    }
    .category-wrapper {
      column-count: 2;
      column-gap: 30px;
    }
    @supports (grid-template-rows: masonry) {
      .category-wrapper {
        display: grid;
        grid-template-columns: repeat(var(--columns), 1fr);
        grid-template-rows: masonry;
        grid-auto-flow: dense;
      }
    }
    .print-category-container {
      break-inside: avoid;
      page-break-inside: avoid;
      & > .print-list-item:last-child {
        margin-bottom: 10px;
      }
    }
    .print-category-name {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
      color: $gray-500;
    }
  }
}
