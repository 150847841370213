@import "src/styles/variables.scss";

.cq-tab-wrapper {
    height: calc(100% - 180px);
}

.cq-container {
    max-width: 1100px;
    width: 100%;
    height: 100%;
}

.questionnaire-header-container {
    padding: 25px 25px 10px 35px;
    border-top: 5px solid $sapphire;
    background: $white;
    box-shadow: 0px 4px 20px $shadow;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    top: 0;
}

.title-description-container {
    display: flex;
    flex-direction: column;
    margin-right: 1.5%;
    width:85%
}

.progress-bar-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
        width: 100px;
        height: 100px;
        transform: rotate(-90deg);
        position: absolute;

        circle {
            fill: none;
            stroke: #f0f0f0;
            stroke-width: 5;
            stroke-linecap: round;

            &:last-of-type {
                stroke: $shamrock;
                transition: all 1s ease-in;
                &.progress-bar-circle {
                    stroke-dasharray: 282px;
                }
            }
        }
    }
}

.number-wrapper {
    width: 95px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid $gray-200;
    border-radius: 50%;

    &>span {
        &:first-child {
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            
            sup{
                font-size: 12px;
                font-weight: 500;
            }

        }

        &:last-child {
            font-size: 12px;
            color: #6b7075;
        }
    }
}

.custom-questions-tab-template-container {
    height: 100%;
    padding: 20px;
    background-color: $gray-background;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .title {
        color: $sapphire;
        font-size: 28px;
        font-weight: 500;
        width: 100%;
        min-height: 40px;
        padding: 0;
        border: none;
        border-bottom: none !important;
        resize: none;
        text-align: justify;
    }

    .description {
        font-size: 16px;
        font-style: italic;
        width: 100%;
        height: 100% !important;
        min-height: 40px;
        padding: 5px 0;
        vertical-align: bottom;
        border: none;
        border-bottom: none !important;
        color: $text-color;
        resize: none;
        outline: none;
        text-align: justify;
    }
}

.questionnaire-section-container{
    overflow-y: auto;
    height: calc(100% - 135px);
    margin-top: 15px;
}

.preview-section-container {
    padding: 1.5%;
    margin-bottom: 1.5%;
    background-color: $white;
    color: $text-color;
    box-shadow: 0px 4px 20px $shadow;
    border-radius: 2px;

    .preview-section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.25px solid rgba(0, 0, 0, 0.2);
        margin: 0 0 10px 0;
        margin-left: 1.5%;
        padding: 10px 0;

        .section-title {
            font-size: 20px;
        }
    }

    .section-delete-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-left: 5%;

        &:disabled {
            opacity: 0.5;
        }
    }

    .question-preview-container {
        margin: 0;

        label {
            font-size: 18px;
            font-weight: 600;
            width: 100%;
        }

        .question-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            position: relative;

            &.active-question {
                background-color: rgba(230, 235, 240, 0.3);
                transition: all 0.5s;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 5px;
                    background-color: $sapphire;
                    border-radius: 4px;
                }
            }

            .question {
                width: 100%;
                overflow: auto;
                padding: 1.5%;
            }
        }

        .answer-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin-bottom: 30px;
            margin-left: 30px;
        }

        textarea {
            border: 1px solid $gray-100;
            border-radius: 2px !important;
            font-size: 16px;
            padding: 7px 13px;
            max-height: 20vh;
            box-shadow: none;
        }

        .invalid-input {
            border-color: $error !important;
            box-shadow: none;
        }

        .non-editable {
            resize: none;
            outline: none;
            cursor: none;
            pointer-events: none;
        }

        .error-text {
            color: $error !important;
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 0;
        }
    }
}

.preview-section-container:last-child{
    margin-bottom: 0px;
}

@media (max-width: 1920px) {
    .preview-title {
        font-size: 30px;
    }
}

@media (min-width: 1920px) {
    .preview-title {
        font-size: 40px;
    }
}

.preview-title {
    color: $sapphire;
    font-weight: 500;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 0;
    border: none;
    border-bottom: none !important;
    resize: none;
    word-wrap: break-word;
}

.multi-choice-preview-wrapper {
    .form-check label {
        font-size: 16px;
        margin-left: 24px;
        font-weight: 400;
        max-width: 100%;
        margin-bottom: 0;
        pointer-events: none;
    }

    .form-check {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        padding-left: 0;
    }

    .form-check input {
        margin: 0;
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 3px;
    }

    .error-radio {
        input {
            accent-color: $error !important;
            visibility: hidden;

            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 3px solid $error;
                visibility: visible;
            }
        }

        label {
            color: $error !important;
        }
    }

    .form-check:last-child {
        margin-bottom: 0;
    }

    &.answer-wrapper {

        .taxpayer-radio,
        .spouse-radio {
            display: flex;
        }
    }
}

.answer-wrapper {
    margin-top: 10px;
    margin-left: 28px;

    .follow-up-answer {
        margin-left: 15px;
    }

    .form-check-inline {
        display: inline-flex;
    }

    .form-check input {
        width: 16px;
        height: 16px;
        margin-left: 0 !important;
        cursor: pointer !important;
    }

    .form-check-input:checked {
        accent-color: $sapphire;
    }

    .form-check label {
        padding: 0px 8px 0px 6px;
        font-size: 16px;
        font-weight: 400;
        pointer-events: none;
    }

    .form-control {
        &.taxpayer-color {
            color: $prince;
        }

        &.spouse-color {
            color: $shamrock-600;
        }
    }

    .yesno-error-radio {
        input {
            accent-color: $error !important;
            visibility: hidden;

            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 3px solid $error;
                visibility: visible;
            }
        }

        label {
            color: $error !important;
        }
    }

    .taxpayer-radio input:checked {
        accent-color: $prince;
    }

    .spouse-radio input:checked {
        accent-color: $shamrock-600;
    }

    .taxpayer-radio,
    .spouse-radio {
        display: inline-flex;

        .form-check-label {
            font-weight: 400;
        }
    }
}

.padding-24 {
    padding-left: 24px;
}

.padding-12 {
    padding-left: 12px;
}

.sub-question-question-border {
    position: relative;

    &:before {
        content: "";
        width: 1px;
        height: 58px;
        background: $sapphire;
        position: absolute;
        left: -25px;
    }
}

.sub-question-label {
    margin-top: 10px;
    position: relative;
    margin-bottom: 0;

    &:before {
        content: "";
        width: 1px;
        height: 10px;
        top: 10px;
        transform: rotate(90deg);
        background: $sapphire;
        position: absolute;
        left: -19px;
    }
}

.yes-no-followup-wrapper>label {
    position: relative;
    margin-bottom: 0;

    &:first-child:before {
        content: "";
        width: 1px;
        height: 10px;
        transform: rotate(90deg);
        background: $sapphire;
        position: absolute;
        left: -19px;
        top: 10px;
    }
}

.followup-question-wrapper {
    margin-top: 18px;
}

.questions-footer {
    background-color: $white;
    display: flex;
    justify-content: flex-end;
    padding: 15px;

    button {
        min-height: 38px;
        min-width: 150px;
    }

    .btn-save-and-close {
        height: 38px;
    }
}

.cq-error-red {
    color: $error;
}

.validation-container {
    span {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        margin-left: 0.6rem;
    }
}

.questions-container {
    display: flex;
    flex-wrap: wrap;

    span {
        font-weight: normal;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .questions-footer {
        button {
            height: 48px;
        }
        .btn-save-and-close {
            height: 48px;
        }
    }
    
}
