.form-label {
  margin: 0;
  margin-bottom: 0;
}

.form-check-input {
  margin-left: 0;
  margin-right: 0;
  position: static !important;
}

.form-check-input:checked {
  background-color: $theme-Sapphire !important;
  border-color: $theme-Sapphire !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: $theme-Sapphire !important;
}

.ss-input {
  border: 1px solid $gray-400 !important;
  border-radius: 2px !important;

  &::placeholder {
    color: $gray-400 !important;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px rgba(13, 110, 253, 0.25) !important;
    border: 1px solid #86b7fe !important;
  }
}

.ss-input-invalid {
  border-color: $error;
  box-shadow: 0px 0px 0px 2px rgba(220, 53, 69, 0.5) !important;
  border: 1px solid #cc4a43 !important;
}

.text-danger {
  color: $error !important;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -30%;
}

// OTP Input control styles

.otp-input-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;

  .info-icon {
    position: absolute;
    right: -15px;
    top: 8px;
  }

  .invalid-feedback {
    display: flex;
  }

  input {
    width: 34px;
    height: 41px;
    border: 1px solid $gray-100;
    background-color: $half-white;
    border-radius: 4px - 2;
    font-size: $size-md * 2;
    padding: 0px;
    text-align: center;

    &:focus-visible,
    &:focus,
    &:focus-within {
      outline: none;
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }

  &.error {
    input {
      border-color: $error;
      color: $error;
    }
  }

  .icon {
    color: $error;
    margin-left: 10px;
  }

  .error-text {
    width: 100%;
    font-size: $size-sm;
    color: $error;
    margin-top: 10px;
  }

  div.mobile-view {
    .invalid-feedback {
      width: 350px !important;
      text-align: center;
    }

    input {
      font-size: 20px !important;
    }
  }
}

.react-tel-input {
  .flag-dropdown {
    border: 1px solid $gray-400 !important;
    border-radius: 2px 0 0 2px;
  }
}

div.mobile-view {
  .otp-auth-page .otp-auth-junk-text {
    margin-top: 25px;
  }
  .otp-input-control-wrapper {
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    input {
      width: 30px !important;
      height: 36px;
      border: 1px solid $gray-100;
      background-color: $half-white;
      border-radius: 4px - 2;
      font-size: 28px;
      padding: 0px;
      line-height: 10px;

      &:focus-visible,
      &:focus,
      &:focus-within {
        outline: none;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .info-icon {
      top: 3px;
    }
  }

  .react-tel-input .form-control {
    max-width: 100%;
  }
}

@media screen and (max-width: 380px) {
  div.mobile-view {
    .otp-input-control-wrapper {
      input {
        width: 25px !important;
      }
    }
  }
}