@import "src/styles/variables.scss";

.session-time {
  display: flex;
  .time {
    font-weight: 800;
    padding-left: 3px;
  }
}

.session-modal {
  .modal-body {
    p {
      font-weight: 600;
      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .modal-header {
    border: 0;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 57px;
    .modal-title {
      font-weight: 700;
    }
  }
  .modal-content {
    width: 450px;
    border-radius: 8px;
    overflow: hidden;
    max-width: 580px;
    width: 100%;
  }
  .modal-footer {
    button {
      height: 38px;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .session-modal {
    .modal-footer {
      button {
        height: 48px;
      }
    }
  }
}
