/* colors */
$navy : #05386b;
$navy-100 : #e6ebf0;
$navy-200 : #b4c3d3;
$navy-300 : #9bafc4;
$navy-400 : #6988a6;
$navy-500 : #05386b;
$navy-600 : #053260;
$navy-700 : #04274b;
$navy-800 : #032240;
$navy-900 : #011120;

$denim : #13294b;
$denim-100 : #e7eaed;
$denim-200 : #d0d4db;
$denim-300 : #a1a9b7;
$denim-400 : #717F93;
$denim-500 : #13294b;
$denim-600 : #0f213c;
$denim-700 : #0b192d;
$denim-800 : #08101e;
$denim-900 : #020407;

$sapphire : #0973ba;
$sapphire-100 : #e6f1f8;
$sapphire-200 : #b5d5ea;
$sapphire-300 : #6babd6;
$sapphire-400 : #3a8fc8;
$sapphire-500 : #0973ba;
$sapphire-600 : #075c95;
$sapphire-700 : #054570;
$sapphire-800 : #042e4a;
$sapphire-900 : #021725;

$shamrock : #669440;
$shamrock-100 : #f0f4ec;
$shamrock-200 : #c2d4b3;
$shamrock-300 : #a3bf8c;
$shamrock-400 : #85a966;
$shamrock-500 : #669440;
$shamrock-600 : #5c853a;
$shamrock-700 : #47682d;
$shamrock-800 : #334a20;
$shamrock-900 : #1f2c13;

$success : #1f8747;
$success-100 : #e9f3Ee;
$success-200 : #a5Cfb5;
$success-300 : #79b791;
$success-400 : #4c9f6c;
$success-500 : #1f8747;
$success-600 : #196c39;
$success-700 : #13512b;
$success-800 : #0c361c;
$success-900 : #092815;

$error : #cc4a43;
$error-100 : #faedec;

$error-200 : #ebb7b4;
$error-300 : #e0928e;
$error-400 : #d66e69;
$error-500 : #cc4a43;
$error-600 : #b8433c;
$error-700 : #8f342f;
$error-800 : #662522;
$error-900 : #521e1b;

$warning : #d69f38;
$warning-100 : #fbf5eb;
$warning-200 : #f3e2c3;
$warning-300 : #ebcf9c;
$warning-400 : #deb260;
$warning-500 : #d69f38;
$warning-600 : #ab7f2d;
$warning-700 : #805f22;
$warning-800 : #564016;
$warning-900 : #2b200b;

$info : #355abf;
$info-100 : #ebeff9;
$info-200 : #c2ceec;
$info-300 : #869cd9;
$info-400 : #5d7bcc;
$info-500 : #355abf;
$info-600 : #2a4899;
$info-700 : #203673;
$info-800 : #15244c;
$info-900 : #0b1226;

$prince : #542d87;
$prince-100 : #eeeaf3;
$prince-200 : #ddd5e7;
$prince-300 : #bbabcf;
$prince-400 : #9881b7;
$prince-500 : #542d87;
$prince-600 : #43246c;
$prince-700 : #321b51;
$prince-800 : #221236;
$prince-900 : #11091b;

$magenta : #ae1b6a;
$magenta-100 : #f7e8f0;
$magenta-200 : #dfa4c3;
$magenta-300 : #ce76a6;
$magenta-400 : #be4988;
$magenta-500 : #ae1b6a;
$magenta-600 : #8b1655;
$magenta-700 : #681040;
$magenta-800 : #460b2a;
$magenta-900 : #230515;

$orange : #d36d13;
$orange-100 : #fbf0e7;
$orange-200 : #f2d3b8;
$orange-300 : #e5a771;
$orange-400 : #dc8a42;
$orange-500 : #d36d13;
$orange-600 : #a9570f;
$orange-700 : #7f410b;
$orange-800 : #542C08;
$orange-900 : #2a1604;

$gray-100 : #898d91;
$gray-200 : #c4c6c8;
$gray-300 : #a6a9ac;
$gray-400 : #898d91;
$gray-500 : #6b7075;
$gray-600 : #565a5e;
$gray-700 : #404346;
$gray-800 : #202223;
$gray-900 : #151617;

$green: #5C8001;

$accent: #88C656;

$default-background-color: #ffffff;
$label-color: #1C2B36;
$text-color : #212529;
$white: #fff;
$black: #000;
$popover-background-color: #f0f0f0;
$background-color-gray: #f0f1f180;
$shadow: rgba(158, 158, 158, 0.3);
$gray-background: #F0F1F1;

$error-color: #CC4A43;
$error-color-100: #FAEDEC;
$error-color-600: #B8433C;
$success-color: #1F8747;
$success-color-100: #E9F3ED;
$warning-color: #D69F38;
$warning-color-100: #FBF5EB;
$backdrop-bg: rgba(0, 0, 0, 0.5);
$half-white: #f5f6fb;

// FONT
$font-size-base: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-md: 18px;
$font-size-l: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

$font-size-base-mobile : 13px;

//FONT FAMILY
$font-family: "Mulish", sans-serif;

// SIZES
$size-xs: 12px;
$size-sm: 14px;
$size-md: 16px;
$size-lg: 18px;
$border-radius: 4px;



//BREAKPOINT

$mobile-max-width: 600px;