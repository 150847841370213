@import '../../../styles/variables.scss';

.accordian {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    transition: height 5s ease;
}

.accordian-body {
    width: 100%;
    padding: 0px;
}

.accordion-button {
    padding: 8px 12px !important;
    background-color: $gray-background !important;
    font-size: 16px;
    color: $text-color !important;
    font-weight: 500;
}

.accordion-button::after {
    background-size: 18px;
}

.accordion-button:focus {
    outline: 0 !important;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: $gray-background !important;
    color: $text-color !important;
}

.accordion-item {
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;
    .accordion-button::after{
        transition-duration: 0.6s;
    }

    .accordion-collapse {
        height: calc(100% - 37px);
        overflow-x: hidden;

        .accordian-body {
            height: 100%;
            overflow-x: hidden;
            padding: 0px;
        }

        .accordian-body>div {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.accordion-item:last-of-type .accordion-button {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}