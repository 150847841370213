@import '../../styles/variables.scss';

.auth-layout {
  overflow: auto;
}
.otp-auth-page {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0px 20px 0px;


  .title-h4 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
  }
  .otp-auth-title {
    font-size: $font-size-xl;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
  }

  .otp-auth-helper-text {
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 24px;
    color: $gray-600;
    letter-spacing: 0px;
    text-align: left;
    margin: 10px 0px 20px 0px ;
  }
  
  .otp-auth-helper-text-when-controls-shown{
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 24px;
    color: $gray-600;
    letter-spacing: 0px;
    text-align: left;
    margin: 10px 0px 28px 0px ;
  }

  .otp-auth-expires-text {
    color: $error;
  }
  .otp-auth-btn {
    width: 250px;
    height: 38px;
    padding: 8px 16px;
    border-radius: 0px;
    margin-top: 20px;
    font-size: 16px;
  }
  .otp-auth-junk-text {
    font-size: $font-size-sm;
    margin-top: 35px;
  }
  .otp-auth-resend-btn {
    background-color: $default-background-color;
    border: none;
    text-align: left;
    padding: 0px;
    font-size: $font-size-sm;
    font-weight: 700;
    text-decoration: underline;
  }

}

div.mobile-view {
  .otp-auth-page {
    padding-top: 53px !important;

    .otp-auth-junk-text{
      margin-top: 51px !important;
    }

    .otp-auth-resend-btn {
      margin-bottom: 21px;
    }

    .otp-auth-helper-text-when-controls-shown{
      margin-top: 43px;
      margin-bottom: 55px;
    }

    .otp-auth-btn {
      max-width: 296px;
      height: 48px;
      margin-top: 24px;
      font-size: 18px;
      width: 100%;
    }
    .title-h4{
    margin-top: 43px;
    }
    .otp-auth-helper-text {
      margin-top: 8px;
    }
  }
}

@media only screen and (min-width: $mobile-max-width) and (max-width: 1080px) {
  .auth-layout .auth-layout-left-section {
    padding: 80px 50px 50px 50px;
    .otp-input-control-wrapper input:not(:last-child) {
      margin-right: 4px;
    }
  }
}