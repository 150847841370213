// the styles that override the default styles
@import 'variables';
div.ribbon.ribbon--yellow {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 10px;
    border-radius: 4px;
    width: 56px;
    height: 49px;
    padding-top: 5px;
    z-index: 100;
    &::after{
        border-top: 20px solid $warning;
        width: 52px;
        border-right: 27px solid transparent;
        border-left: 27px solid transparent;
        top: 48px;
        left: 1px;
    }
}

.sign-modal.modal {
    .modal-content {
        border-radius: 4px;
        input.form-control,
        .signature-pad-wrapper.type-tab {
            border-radius: 4px !important;
        }
        .btn-clear-signature {
            padding: 5px 15px !important;
            border: 1px solid $sapphire;
            color: $sapphire;
            background-color: $white;
            font-size: 14px !important;
            border-radius: 4px !important;
            margin-bottom: 5px;
            width: 93px;
            &:hover,
            &:focus {
                box-shadow: none !important;
                border: 1px solid $sapphire;
                outline: none;
            }
        }
        .type-signature-form-group-two {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .signature-pad-wrapper.type-tab {
                width: 100%;
            }
        }
        label {
            font-weight: 600;
        }
        .type-signature-form-group-one-label {
            margin-bottom: 5px;
        }
        .draw-signature-form-group-label {
            margin-bottom: 0;
            line-height: 48px;
        }
        .rwt__tab[aria-selected=true]{
            color: $sapphire;
            font-weight: 700;
        }
    }
    .sign-modal-footer.modal-footer {
        button {
            border-radius: 4px !important;
            font-size: 16px;
            &.sign-modal-footer-cancel-button {
                border: 1px solid #898D91 !important;
                min-width: 100px !important;
            }
        }
    }
}