.status-update-notification-modal {
    .form-group {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      label {
        margin-bottom: 0px;
        font-size: 14px;
      }
  
      span {
        font-weight: 700;
  
      }
      input {
        width: 60%;
      }
    }
  }