@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

header {
    height: 80px;
    background-color: $sapphire-600;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drawer-icon {
        display: none;
        padding: 10px 20px;
        cursor: pointer;
    }

    .logo-container {
        width: 160px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        img{
            max-width: 100%;
            height: 100%;
            object-fit:contain;
        }

        .company-name {
            font-size: $font-size-l;
            font-weight: 500;
            color: $white;
            width: 100%;
        }
    }

    .user-dropdown {
        color: $white;
        display: flex;
        align-items: center;
        span {
            max-width: 180px;
        }
    }

    .right-container {
        min-width: 30px;
        color: $white;
        &>* {
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    &.type-1 {
        background-color: $white;
        padding: 0;
        color: $text-color;

        .logo-container {
            width: 350px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 45px;
            background: $sapphire-600;

            .company-name {
                text-align: center;
                font-size: $font-size-xl;
            }
        }

        .user-dropdown {
            color: $text-color;
        }

        svg {
            path {
                fill: $text-color;
            }
        }

        .right-container {
            padding: 10px 20px;
            color: $black;
        }
    }
}

#popover-user-dropdown {
    min-width: 240px;
    border-radius: 4px;
    font-size: $font-size-base;
    z-index: 999;

    .popover-body {
        padding: 15px 0;

        .user-name {
            color: $sapphire;
        }

        hr {
            margin: 10px 0;
        }

        &>div {
            padding: 0 15px;
        }
    }
}

#popover-contact-popup {
    width: 185px;
    border-radius: 4.8px;
    font-size: $font-size-base;
    text-align: center;

    &.bs-popover-bottom>.popover-arrow::after {
        border-bottom-color: $popover-background-color;
    }

    .popover-header {
        background-color: $popover-background-color;
        font-weight: 500;
    }

    .email-address a {
        color: $sapphire;
        text-decoration: none;
    }
}

.my-account-modal.custom-modal-container {
    z-index: 9999;
    .modal-header {
        background-color: $navy;
        border: 0;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .modal-title {
            color: $white;
            font-weight: 500;
        }
    }
    .modal-content {
        width: 450px;
        border-radius: 5px;
        overflow: hidden;
    }
    .modal-footer {
        .ss-btn-primary:hover,
        .ss-btn-secondary:hover {
            text-decoration: none;
        }
    }
}

.header-step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    .step-wrapper {
        color: $sapphire-200;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: $font-size-xs;
        text-align: center;

        &.current {
            font-weight: 600;
            color: $white;
        }

        &.done {
            .step {
                background-color: $accent;
                border-color: $accent;
            }
        }
    }

    .step {
        @include step-bullet;
        margin-bottom: 5px;

        &.current {
            background-color: $white;
            color: $sapphire-600;
        }
    }

    .horizontal-line {
        height: 2px;
        width: 100%;
        margin: 0 10px;
        background-color: $gray-200;
        margin-bottom: 25px;
        max-width: 100px;
    }
}

.my-account-form {

    .spouse-name {
        font-weight: 600;
        margin-bottom: 5px;

        span {
            font-weight: 400;
            color: $sapphire;
        }
    }

    .form-label {
        font-weight: 600;
    }

    hr {
        background-color: $gray-500;
    }
}

div.mobile-view {
    header {
        padding: 10px 10px;
        .drawer-icon {
            display: block;
        }

        &.type-1 {
            background-color: $sapphire-600;

            svg path {
                fill: $white;
            }

            .logo-container {
                width: 280px;
                img {
                    width: 100%;
                }
            }
        }
        .header-step-container {
            .horizontal-line {
                max-width: 10px;
            }
        }
    }
    .my-account-wrapper {
        height: calc(100% - 80px);
        overflow: auto;
    }
}

.cpa-message-modal {
    .modal-header {
        font-size: $font-size-md;
        font-weight: 700;
    }

    .modal-content {
        border-radius: 5px;
    }

    .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }
}

.side-menu-drawer {
    .drawer-body {
        position: relative;
        height: 100%;
        z-index: 0;

        &>div:not(.bg-image) {
            padding: 15px 20px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                background-color: $white;
                height: .25px;
                width: 100%;
                left: 0;
                bottom: 0;
            }
        }

        .greet {
            font-size: $font-size-l;
            font-weight: 500;
        }

        .bg-image {
            position: absolute;
            bottom: 10px;
            right: 0;
            z-index: -1;
        }
    }
}

.mobile-header {
    padding: 10px 20px;
    height: 80px;
    background-color: $sapphire-600;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .placeholderbox {
        height: 20px;
        width: 20px;
    }

    .logo-container {
        width: 280px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 45px;
        
        img{
            width: 100%;
            height: 100%;
            object-fit:contain;
        }
    }
}

div.mobile-view {
    .header-step-container {
        width: 100%;
        padding: 0 10px;

        .step {
            height: 24px;
            min-width: 24px;
            font-size: 12px;
        }
    }

    header .drawer-icon {
        padding: 10px;
    }

    .my-account-form {
        & + button {
            height: 48px;
            font-size: 18px;
        }
    }
}