.custom-modal-container {
  .modal-dialog {
    width: 100%;
    max-width: unset;
  }

  .modal-header {
    color: $text-color;
    padding: 16px;
    border: 1px solid #DEE2E6;
  }

  .modal-footer {
    button {
      font-size: 16px;
      min-height: 38px;
    }

    .ss-btn-primary {
      margin-left: 8px;
    }
  }

  .modal-title {
    color: $text-color;
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
  }

  .modal-content {
    margin: auto;
    width: 580px;
    border-radius: 4.8px;
  }
}

.white-modal {
  .modal-header {
    padding: 10px 15px;

    .modal-title {
      font-size: $font-size-l;
      font-weight: 700;
    }
  }
}

.notice-popup {
  .modal-header h2 {
    font-weight: 700;
  }

  .modal-footer {
    justify-content: center;
    button {
      width: 175px;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1025px) {
  .modal-dialog {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1026px) {
  .modal-dialog {
    margin-top: 3%;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .modal {
    width: 100vw;
  }
  .modal-dialog {
    margin: 30% 24px 30px 24px;
    &.modal-dialog-centered {
      min-height: unset;
    }
  }
  .modal-content {
    border-radius: 5px;
    margin: 0 !important;
  }
  .modal-body {
    padding: 25px 15px;
  }
  .modal-footer {
    button {
      padding: 8px 16px;
      font-size: 20px;
      height: 48px;
    }
  }
  .custom-modal-container {
    .modal-dialog {
      width: calc(100% - 50px);
    }

    .modal-content {
      width: 100%;
    }

    .modal-footer {
      & > div {
        width: 90%;
        display: flex;
        justify-content: center;

        button {
          font-size: 16px !important;
          height: 48px;
          white-space: nowrap !important;
          &.ss-btn-primary {
            min-width: 125px;
          }
          &.ss-btn-secondary {
            min-width: 125px;
            flex: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px){
  .custom-modal-container {
    .modal-footer {
      & > div button {
        min-width: unset !important;
        font-size: 16px !important;
        white-space: nowrap !important;
        overflow: hidden;
      }
    }
  }
}