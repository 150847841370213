div.Toastify__toast {
    padding: 0;
    font-family: $font-family;
    font-size: $font-size-base;
    min-height: 58px;
    border-radius: 4px;
    overflow: hidden;

    .Toastify__toast-body {
        margin: 0;
    }

    .toast-wrapper {
        position: relative;
        height: 100%;
        padding: 5px 0 5px 15px;
        display: flex;
        align-items: center;
        color: $text-color;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
        }

        .toast-icon {
            margin-right: 10px;
        }

        &.success {
            background-color: $success-color-100;

            &::before {
                background-color: $success-color;
            }
        }

        &.error {
            background-color: $error-color-100;

            &::before {
                background-color: $error-color;
            }
        }

        &.warning {
            background-color: $warning-color-100;

            &::before {
                background-color: $warning-color;
            }
        }
    }

    &--success {
        background-color: $success-color-100;
    }

    &--error {
        background-color: $error-color-100;
    }

    &--warning {
        background-color: $warning-color-100;
    }

    .close-button {
        display: flex;
        align-items: center;
        padding-right: 15px
    }

    .Toastify__toast-icon {
        display: none;
    }

    .Toastify__toast-body {
        padding: 0;

        &>div {
            height: 100%;
        }
    }
}

.Toastify__toast-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
    width: auto;
}

//Alert
.validation-alert {
    background-color: $error-color-100;
    border: 0;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background-color: $error-color;
    }
}

div.mobile-view {
    .Toastify__toast-container {
        width: 100%;
    }
}