@import "../../../styles/variables.scss";

.auth-layout {
  height: 100%;
  display: flex;
  position: relative;
  white-space: pre-line;

  .auth-layout-left-section {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $default-background-color;
    padding: 80px 100px 50px 150px;
    height: 100%;

    .left-container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .title-h1 {
      text-align: left;
      
      span {
        color: $sapphire;
      }
    }

    .otp-auth-contact-container{ 
        display:flex;
        align-items: center ;
        span {
          font-weight: 700;
          color: $navy;
        }
        
  }

    .auth-footer {
      text-align: center;
      width: 100%;

      span {
        line-height: 12px;
      }

      a {
        font-size: $font-size-sm;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: $gray-500;
      }
    }

    .auth-layout-company-logo-container {
      text-align: left;
      font-size: $font-size-xs;
      height: 40px;
      img {
        max-height: 100%;
      }
      div{
        height: 40px;
        opacity: 0;
      }
      p{
        padding-bottom: 10px;
      }
    }
  }

  .auth-layout-right-section {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $sapphire-600;
  }
}

div.mobile-view {
  .auth-layout {
    
    .auth-layout-left-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      padding: 47px 30px 47px 30px;
      height: 100%;
      overflow: scroll;

      .left-container{
        width: 100%;
      }

      img {
        max-width: 100%;
      }
  }

    .auth-layout-right-section {
      display: none;
    }
  }
}