@import "src/styles/variables.scss";

.loader-style{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1000;
    flex-direction: column;
    .text-primary{
        color: $navy !important;
    }
}