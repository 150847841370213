@import "src/styles/variables.scss";

.spouse-required-modal {
  
  .modal-content{
    max-width: 550px !important;
    min-height: 392px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    label {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 600;
    }

    span {
      font-weight: 700;
    }

    input {
      width: 60%;
      border: 1px solid $gray-400;
      border-radius: 4.8px;
      padding: 8px 16px;
    }
  }

  .emailaddress-required-indicator {
    color: $error-color;
  }

  @media only screen and (max-width: 768px) {
    .form-group {
      input {
        width: 90%;
        height: 48px;
        border-radius: 5px;
        padding: 8px 16px;
        border-color: $gray-400;
      }
    }
  }
}

.esign-page-container {
  height: calc(100% - 180px) !important;
  .left-panel.collapsed {
    height: 0;
    min-width: 0;
  }
}

.esign-footer {
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  padding: 15px;

  & > *:last-child {
    margin-left: 10px;
  }

  button:first-child {
    margin-right: auto;
  }

  button.button-outline-primary {
    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      color: $sapphire;
      text-decoration: none;
      outline: unset;
      box-shadow: none;
      border-color: $sapphire;
    }
  }

  button:last-child {
    margin-left: 10px;
  }
  button {
    height: 38px;
    min-height: 36px;
    min-width: 150px;
  }
}

.rct-checkbox {
  display: none !important;
}

.file-list-tree-view-container {
  display: block;
  width: 100%;
  overflow-x: hidden;
  .react-checkbox-tree > ol {
    width: calc(100% + 25px);
    & ol {
      width: 100% !important;
    }
  }
  .rct-bare-label {
    width: 220px;
    & > span {
      display: flex;
    }
  }
  .parentTreeNode {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 190px;
  }
  .rct-node-clickable {
    display: flex;
  }
  .childTreeNode {
    max-width: 170px;
  }
}

div.mobile-view {
  div.header .right-section {
    max-width: unset !important;
  }

  .esign-page-container {
    height: calc(100% - 150px) !important;

    .file-list-tree-view-container {
      .parentTreeNode {
        width: 175px;
      }
      .childTreeNode {
        max-width: 150px;
      }
    }
  }

  #awesome-pdf-viewer .toolbar {
    display: flex;
    justify-content: space-between;
    & > div {
      width: auto !important;
      display: flex;
    }
  }

  .page-viewer {
    width: 100% !important;
  }

  .esign-footer {
    justify-content: space-between;

    & > * {
      width: 49%;
    }
    button {
      height: 48px;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .spouse-required-modal.custom-modal-container {
    .modal-dialog,
    .modal-content {
      width: unset;
      max-width: 100%;
    }

    .modal-footer {
      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .spouse-required-modal.custom-modal-container {
    .modal-header {
      .modal-title {
        font-size: 18px;
      }
    }
    .modal-footer {
      button {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .spouse-required-modal.custom-modal-container {
    .modal-footer {
      button {
        font-size: 18px;
      }
    }
  }
}
