@import 'src/styles/variables.scss';
@import 'src/styles/branding.scss';

.drl-container {
    padding: 30px;
    padding-bottom: 10px;

    .top-section {
        display: flex;

        .progress-bar-container {
            background-color: #{$gray-background}66;
            padding: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .count-box {
                background-color: $white;
                padding: 10px;
                text-align: center;
                color: $gray-500;
                font-size: 12px;
                width: 132px;

                div {
                    line-height: 14px;
                }

                .count {
                    font-size: 40px;
                    font-weight: 300;
                    margin: 0;
                    line-height: 28px;
                }

                &:nth-child(1) .count {
                    color: $sapphire;
                }

                &:nth-child(2) .count {
                    color: $success;
                }

                &:nth-child(3) .count {
                    color: $gray-500;
                }
            }
        }

        .file-upload-outer-container {
            width: 100%;

            .file-upload-progress-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: 1px dashed $sapphire;
                padding: 30px 5%;

                .file-upload-progress-inner-container {
                    margin-left: 10px;
                    width: calc(100% - 60px);


                    .file-upload-progress-bar-track {
                        margin: 5px 0px;
                        width: 100%;
                        height: 3px;
                        border-radius: 3px;
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    .filename{
                        max-width: 700px ;
                    }

                    .file-upload-progress-bar-thumb {
                        border-radius: 4px;
                        width: 0%;
                        height: 3px;
                        background-color: $sapphire-600;
                    }
                }

                label {
                    margin: 0;
                }

                .filename {
                    display: block;
                    color: $gray-400;
                    font-size: $font-size-sm;
                }
            }
        }

        .drl-upload {
            height: auto;


            .file-upload-inner-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .text-container {
                    margin: 0 20px;
                    display: flex;
                    flex-direction: column;

                    label:not(.file-type-label) {
                        color: $text-color;
                        font-weight: 500;
                        margin: 0;
                    }

                    .file-type-label {
                        color: $gray-400;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .list-container {
        height: calc(100% - 160px);
        padding: 20px 0;

        &>.row {
            height: 100%;
        }

        .title {
            font-weight: 700;
            font-size: $font-size-md;
            margin-bottom: 15px;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            background-color: $white !important;
            z-index: 999 !important;

            span {
                font-weight: 400;
            }
        }

        .category-name {
            color: $gray-500;
            font-size: $font-size-sm;
            font-weight: 700;
            margin-bottom: 10px;
            max-width: 250px;
        }

        .unrecognized-documents-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            margin-left: 30px;
            width: 70%;
            max-width: 100%;
        }

        .drl-label-span {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 65% !important;
        }

        .list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            margin-bottom: 5px;
            height: 26px;
            width: 95%;
            max-width: 100%;

            .drl-label-span {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80% !important;
            }

            .mapped-file-count {
                font-weight: 700;
                color: $theme-Sapphire;
            }

            .list-item-actions {
                display: none;

                button {
                    border: none;
                    background-color: transparent;
                }
            }

            &:hover {
                .list-item-actions {
                    display: flex;
                    transition: transform 0.3s ease-in-out;
                }
            }

        }

        .drl-list-item {
            svg {
                position: absolute;
            }

            span {
                margin: 20px 10px 20px 20px;
                position: relative;
            }

            &:hover {
                .list-item-actions {
                    display: inline-flex;

                    button {
                        position: relative;
                        margin: 5px;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .NotApplicable {
            color: $magenta;
            cursor: pointer;
            transform: translateX(8px);
            white-space: nowrap;
            font-weight: 700;
            border: none;
            background: none;
            font-size: 16px;
        }

        .documents-list {
            display: flex;
        }
    }

    &.without-progress-bar {
        .list-container {
            height: calc(100% - 160px);

            &>.row {
                align-content: flex-start;
            }
        }
    }

    .categorized-container,
    .uncategorized-container {
        height: 85%;
        overflow-y: auto;
    }


    .my-masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -30px;
        width: 100%;
    }

    .my-masonry-grid_column {
        padding-left: 10px;
        background-clip: padding-box;

        &:nth-child(1) {
            padding-left: 30px;
        }

        .category-container {
            max-width: 100%;
            margin: 5px;
        }

        .unCategorized-list-item {
            max-width: 100%;
            margin: 5px;
        }
    }

    .uncategorized-container {
        .documents-list {
            grid-template-columns: repeat(2, 49%);
        }
    }

    .tag {
        padding: 3px 10px;
        height: 38px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        font-size: $font-size-sm;
        background-color: #{$sapphire-100};
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: 0;
        margin-bottom: 10px;

        &::before {
            content: "";
            left: 0;
            top: 0;
            position: absolute;
            bottom: 0;
            width: 5px;
            background-color: $sapphire;
        }
    }
}

.drl-tree {
    display: flex;
    flex-direction: column;
}

.drl-footer {
    display: flex;
    justify-content: flex-end;
    background-color: $white;

    .button-link {
        color: $sapphire;
        text-decoration: underline;
    }

    button {
        width: 150px;
        height: 38px;
        min-height: 36px;
    }

    .btn-save-and-close {
        height: 36px;
    }

}

.footer {
    position: fixed;
}

.file-preview-modal {

    #multi-select-drl-dropdown.dropdown-toggle {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: $gray-500;
        border-radius: 4.8px !important;
        margin-bottom: 10px;

        &.show {
            background-color: $white;
            color: $black;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        &:hover {
            background-color: $white;
            color: $black;
        }

        &:focus {
            box-shadow: none;
        }

        &:active {
            transform: scale(1);
        }
    }

    .dropdown-menu {
        width: 50%;
        max-height: 50vh;
        overflow: auto;
        border-radius: 0px;
        top: -3px !important;
        border-color: $gray-500;
        border-bottom-left-radius: 4.8px !important;
        border-bottom-right-radius: 4.8px !important;

        label {
            width: 100%;
        }
    }

    .preview-label {
        font-size: $font-size-sm;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .file-name {
        font-size: $font-size-md;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .page-viewer {
        width: 100%;
    }

    .modal-dialog {
        max-width: 854px;
    }

    .modal-footer {
        button {
            height: 38px;
            min-height: 38px;
        }
    }
}

.edit-document-modal {

    .modal-dialog {
        max-width: 500px;
    }

    .file-name {
        font-size: $font-size-md;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .title {
        font-size: 12px;
        font-weight: 700;
        color: $gray-500;
        margin-top: 10px;
    }

    .other-label {
        width: 189px;
    }

    .form-check-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
    }

    .modal-footer {
        button {
            min-height: 38px;
        }
    }
}


.finish-modal {
    .modal-footer {
        button {
            height: 38px;
            min-height: 38px;
        }
    }
}

div.mobile-view {
    .drl-container {
        padding: 0;
        height: calc(100% - 80px);
        overflow: auto;

        .content {
            padding: 10px;
        }

        .container-wrapper {
            height: calc(100% - 145px);
        }

        .top-section {
            flex-direction: column-reverse;
            margin: 0;

            &>.col {
                padding: 0;
            }

            .progress-bar-wrapper {
                width: 64px;
                height: 64px;

                svg {
                    height: 64px;
                    width: 64px;
                }
            }

            .file-upload-outer-container {
                padding: 20px;
            }

            .number-wrapper {
                height: 59px;
                width: 59px;
            }

            .progress-bar-container {
                padding: 10px;
                background-color: $navy-100;
            }

            .document-title {
                font-size: $font-size-md;
                font-weight: 700;
            }

            .document-description {
                font-size: $font-size-sm;

                &>div {
                    padding: 0 3px;

                    &:nth-child(1) {
                        span {
                            color: $sapphire;
                        }

                        border-right: 1px solid $text-color;
                    }

                    &:nth-child(2) {
                        span {
                            color: $success;
                        }

                        border-right: 1px solid $text-color;
                    }

                    &:nth-child(3) {
                        span {
                            color: $error-color;
                        }
                    }
                }
            }

            .file-upload-container {
                height: 100px;
                background-color: $white;
                position: relative;

                button {
                    position: absolute;
                    inset: 0 0 0 0;
                    padding-top: 45px;
                    color: $navy;
                }
            }
        }

        .file-upload-inner-container {
            flex-direction: column;
        }

        .list-container {
            height: calc(100% - 292px);
            overflow-y: auto;
            padding: 10px 20px;
            min-height: 100px;

            &>.row {
                display: block;
            }

            .title {
                position: static;
                margin-top: 10px;
            }
        }

        &.without-progress-bar {
            .list-container {
                height: calc(100% - 208px);
            }
        }

        .categorized-container,
        .uncategorized-container {
            height: unset;
            overflow: unset;
        }

        .uncategorized-container {
            .my-masonry-grid_column {
                padding-left: 45px;
            }
        }

        .uncategorized-container .documents-list {
            grid-template-columns: repeat(1, 100%);
        }
    }

    .drl-footer {
        display: flex;
        justify-content: center;
        padding: 10px;
        position: static;
        width: 100%;

        button {
            width: 49%;
            height: 48px;
            font-size: 20px;
            border-radius: 2px !important;
        }

    }
}


@media screen and (max-width: $mobile-max-width) {
    .edit-document-modal {
        .modal-body {
            max-height: 60vh;
            overflow: auto;
        }

        .modal-footer {
            display: flex;
            justify-content: center;

            button {
                width: 44%;
                min-height: 48px;
            }
        }
    }

    .file-preview-modal {

        #multi-select-drl-dropdown.dropdown-toggle {
            width: 100%;
        }

        .dropdown-menu {
            width: 100%;
        }
        .modal-footer {
            justify-content: center;

            button {
                width: 175px;
                min-height: 48px;
            }
        }
    }

    .finish-modal {
        .modal-footer {
            button {
                width: 47%;
                height: 48px;
            }
        }
    }
}

@media only screen and (min-width: $mobile-max-width) and (max-width: 1080px) {
    .drl-container {
        .list-container {
            height: calc(100% - 280px);
        }
    }
}

.action-btn-wrapper {
    margin-top: 7px !important;
    margin-bottom: 19px;
    position: relative;
    width: 100%;
    height: 31px;
    padding: 0 !important;
    display: flex;
    justify-content: flex-end;
}

.print-button {
    width: 70px !important;
    display: flex;
    align-items: center;
    min-height: 31px;
    font-size: 14px;
    padding: 4px 8px;
    min-width: unset;

    &.disabled {
        cursor: not-allowed;

        svg {
            path {
                fill: $gray-400;
            }
        }
    }

    svg {
        margin-right: 8px;
    }
}

#height-with-print-btn {
    height: calc(100% - 90px);
}