@import './variables.scss';
button {
    cursor: pointer;
    border-radius: 4px;

    &:active {
        transform: scale(0.98);
        outline: none;
    }

    &:focus {
        outline: none !important;
    }

    &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5;
    }
}

hr {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
    background-color: $sapphire;
}

.file-preview-modal-body {
    #react-doc-viewer {
        height: 50vh;
        overflow-y: auto;
        #proxy-renderer,#msdoc-renderer{
            height: 100%;
        }
    }

    #awesome-pdf-viewer {
        height: 50vh !important;
        .header {
            width: 100%;
            .left-section,
            .right-section {
                width: 0;
                padding: 0;
            }
            .middle-section {
                width: 100% !important;
                max-width: 100% !important;
            }
        }
    }
    #no-renderer-download {
        display: none;
    }
}