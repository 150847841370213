@import "~bootstrap/scss/bootstrap";
.App {
  text-align: center;
}
.printable-area {
  visibility: hidden;
}
@media print {
  @page {
    size: 11in 8.5in;
    margin: 20px 20px 20px 12px;
    color: #212529;
  }
  body * {
    visibility: hidden;
  }
  .printable-area,
  .printable-area * {
    visibility: visible;
    display: block;
  }
  .printable-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
