@import './variables.scss';

/* my added styles */
.main {
  display: flex;
  height: calc(100% - 45px);

  .accordian-container {
    width: 100%;
    height: calc(100% - 68px);
  }

  &.is-loading {
    .page-viewer {
      overflow: hidden;
    }
  }
}

/* ======================== */

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.no-padding {
  padding: 0px 0px 0px 0px !important;
}

.form-control {
  border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
}

.btn-xs {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: smaller !important;
}

.modal-content {
  border-radius: 0px;
}

.modal-title {
  display: contents;
}

.view-layout {
  height: 100% !important;
  border: 1px solid #e8e8e8;
  background-color: $gray-400;
}


.toolbar {
  display: inline-flex;
}

.zoom-options-contaner {
  display: flex;
}

.header {
  /* position: relative; */
  max-height: 45px !important;
  min-height: 45px !important;
  background-color: #ffffff !important;
  border-radius: 0px !important;
  padding: 0px !important;
  border-bottom: 1px solid #e8e8e8;
  z-index: 2;
}

.resizable-horizontal {
  resize: horizontal;
  overflow: hidden;
}

.resizable-vertical {
  resize: vertical;
  overflow: hidden;
}

.resizable-both {
  resize: both;
  overflow: hidden;
}


.toolbar-item-icon {
  color: rgba(0, 0, 0, 0.54)
}

.toolbar-item-form-control {
  padding: 3px !important;
  margin-top: 6px !important;
  font-size: 12px !important;
  height: calc(1.0em + .75rem + 2px) !important;
  float: left;
  text-align: center;
  padding: 5px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.54);
}


.toolbar-item-text-box {
  width: 50px !important;
}

.toolbar-item-search-text-box {
  width: 200px !important;
  text-align: left;
}

.toolbar-item-select {
  width: 130px !important;
}


.toolbar-item-label {
  float: left;
  padding: 10px;
  font-size: small;
}


.left-panel {
  border-right: 1px solid #e8e8e8;
  left: 0px;
}


.right-panel {
  border-left: 1px solid #e8e8e8;
  right: 0px;
}


.side-panel {
  background-color: #ffffff !important;
  width: 100%;
  height: 100%;
  transition: width .2s;
  position: absolute;
  border: 1px solid #e8e8e8;
  z-index: 3;
  overflow-y: hidden;
  max-width: 225px;
  min-width: 206px;

  .form-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
    margin-top: 10px;
    .Select {
      width: 140px;
      margin-left: 20px;
      font-size: 14px;

      .Select-value {
        font-size: 14px;
      }
    }
  }
}

.active-panel {
  position: unset;
  z-index: 0;
}

/* For Tablets */
@media only screen and (min-width: $mobile-max-width) and (max-width: 1080px) {
  .toolbar {
    display: flex;
    justify-content: space-between;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  .side-panel {
    background-color: #ffffff !important;
    width: 310px;
    transition: width .2s;
    position: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .arrow-down {
    margin-left: -18px;
  }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  .side-panel {
    background-color: #ffffff !important;
    width: 375px;
    transition: width .2s;
    position: unset;
  }

  .arrow-down {
    margin-left: -18px;
  }
}

.right-panel.collapsed {
  width: 0px !important;
  visibility: hidden;
}


.left-panel.collapsed {
  width: 0px !important;
  visibility: hidden;
}

.side-menu-container {
  width: 50px;
  height: 100%;
}

.left-side-menu-container {
  float: left;
  border-right: 1px solid #e8e8e8;
}

.right-side-menu-container {
  float: right;
  border-left: 1px solid #e8e8e8;
}
// 

.page-viewer {
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  position: relative;
}

.pdfViewer {

  .canvasWrapper {
    height: 100% !important;

  }
}
.pdfViewer .page {
  border-color: #757575;
  border-style: none;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  box-sizing: content-box;
  border-image: none !important;
  margin: 8px auto 5px auto !important;
  border: none !important;
  position: relative;
}


.controls-layer {
  /* position: absolute; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


.toolbar-item {
  width: 20px;
  height: 20px;
}

.toolbar-section {
  display: flex;
}

.toolbar-item-container {
  height: inherit;
  padding-top: 6px;
  width: 40px;
  height: 38px;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
}

.toolbar-item-container:hover {
  background-color: rgba(226, 226, 226, 0.9844);
}


.left-section {
  padding: 5px;
}

.middle-section {
  padding: 5px;
}

.right-section {
  padding: 5px;
}

.right-section .icon-bar {
  float: right;
}

.icon-bar a {
  float: left;
  width: 40px;
  text-align: center;
  padding: 5px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.68);
  margin-right: 5px;
  cursor: pointer;
}

.icon-bar .icon:hover {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.8)
}

.icon-disabled,
.disabled {
  color: #cdcdcd !important;
  cursor: not-allowed;
}

.active {
  background-color: #4CAF50;
}


.divider {
  border-right: 1px solid #ccc;
  margin: 5px 0 0;
  width: 10px !important;
  height: 25px;
}

.awesome-pdf-viewer-loader.visible {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.82);
  z-index: 99;
}


.awesome-pdf-viewer-loader.visible>.icon {
  border: 2px solid #2196F3;
  border-top-color: rgba(0, 0, 0, .2);
  border-right-color: rgba(0, 0, 0, .2);
  border-bottom-color: rgba(0, 0, 0, .2);
  width: 35px;
  height: 35px;
  margin-top: 30%;
  margin-left: 50%;
  -webkit-animation: circle infinite .75s linear;
  -moz-animation: circle infinite .75s linear;
  -o-animation: circle infinite .75s linear;
  animation: circle infinite .75s linear;
  border-radius: 100%;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* Signtaure Controls */

.document-control {
  border-radius: 0px !important;
  cursor: pointer !important;
  display: flex !important;
  position: absolute !important;
  z-index: 99;


}

.label-control {
  font-weight: bold;
  background-color: #e1ffbe;
}

.document-control-icon-container {
  margin-top: -5px;
  border-radius: 0px !important;
  margin-left: -9px;
  height: inherit;
  width: 22px;
  line-height: 1.0 !important;
  padding: 0px !important;
  background-color: #008d20 !important;
  border-color: #008d20 !important;
}


.document-control span {
  line-height: 0;
  margin-left: 5px;
}


.signed-control-box {
  height: 100%;
  width: 100%;
  border: 1px solid #dadada;
  cursor: pointer !important;
  background-color: #eaffeb;
  position: absolute !important;
  z-index: 1;
}

.signed-control-box-header {
  height: 100%;
}




.signed-control-box-header-preview {
  height: 100%;
  width: 100%;
}

.signed-control-box-footer {
  height: 50%;
  display: flex;
  font-size: x-small;
  align-items: center;
}

.signed-control-box-footer-left-text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signed-control-box-footer-left-text {
  float: left;
}

.signed-control-box-footer-right-text-container {
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signed-control-box-footer-right-text {
  float: right;
}

.control-selected {
  box-shadow: #ffc107 0px 0px 0px 2px;
  border: 1px dashed #8d8d8d !important;
}

.display-content {
  display: contents;
}

.width-height-100-percent {
  height: 100%;
  width: 100%;
}

.vertical-align-inherit {
  vertical-align: text-top !important;
}

/* Control navigation */
.control-navigation-popup {
  top: 0px !important;
  border-radius: 0px !important;
  border: 1px solid #e2e2e2 !important;
  z-index: auto !important;
  /* width: 15% !important; */
}

.navigation-start-control {
  width: 110px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  border-radius: 0px;
  background: #008478;
  z-index: 99;
  position: absolute;
  top: 10px;
  left: -20px;
  cursor: pointer;
  user-select: none;
}

.navigation-start-control:hover {
  background-color: #029d8f;
}

.navigation-start-control::after {
  content: '';
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 10px solid #10645d;
  left: 0px;
  top: 30px;
}

.navigation-start-control-text {
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: $text-color;  
  animation: shake 1.5s;
  animation-iteration-count: infinite;
  user-select: none;
}


@keyframes shake {
  0% {
    transform: translate3d(0px, 0, 0);
  }

  10% {
    transform: translate3d(0px, 0, 0);
  }

  20% {
    transform: translate3d(0px, 0, 0);
  }

  30% {
    transform: translate3d(-5px, 0, 0);
  }

  40% {
    transform: translate3d(0px, 0, 0);
  }

  50% {
    transform: translate3d(-5px, 0, 0);
  }

  60% {
    transform: translate3d(0px, 0, 0);
  }

  70% {
    transform: translate3d(0px, 0, 0);
  }

  80% {
    transform: translate3d(0px, 0, 0);
  }

  90% {
    transform: translate3d(0px, 0, 0);
  }

  100% {
    transform: translate3d(0px, 0, 0);
  }
}

/* Ribbon */

.ribbon {
  cursor: pointer;
  text-align: center;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  display: block;
  width: 69px;
  height: 56px;
  background-color: #5f9654;
  color: white;
  float: left;
  padding-top: 10px;
  position: absolute !important;
  transform: translate3d(0, 0, 0);
  top: 0px;
  font-size: small !important;
  left: 5px;
  z-index: 2;
}

.ribbon:after {
  content: "";
  width: 69px;
  height: 0;
  border-right: 33px solid transparent;
  border-left: 34px solid transparent;
  /* border-top: 1.5rem solid #CCCCCC; */
  position: absolute;
  top: 56px;
  left: 0;
}

.ribbon.ribbon--green {
  /* background: linear-gradient(to bottom, #5f9654 0%, #65b045 100%); */
  background-color: #5f9654;
}

.ribbon.ribbon--green:after {
  border-top: 1.5rem solid #5f9654;
}

.ribbon.ribbon--yellow {
  background-color: $warning-500;
}

.ribbon.ribbon--yellow:after {
  border-top: 1.5rem solid $warning-500;
}


.shepherd-footer,
.shepherd-header {
  padding: 0px !important;
}

.ribbon.ribbon--yellow:after {
  border-top: 1.5rem solid $warning-500;
}

.navigation-btn-next {
  width: 100% !important;
  height: 100% !important;
}

.navigation-btn-next:focus {
  outline: none !important;
}

.control-navigation-popup:focus {
  outline: none !important;
}

.control-navigation-popup {
  width: 50px !important;
  background: red !important;
  text-align: center !important;
  margin-right: 20px !important;
  position: fixed !important;
}

.control-navigation-popup:after,
.control-navigation-popup:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 50%;
  left: 100%;
}

.control-navigation-popup:after {
  bottom: 0;
  background: linear-gradient(to right bottom, #FF9800 50%, transparent 50%);
}

.control-navigation-popup:before {
  top: 0;
  background: linear-gradient(to right top, #FF9800 50%, transparent 50%);
}


/* Signature pad */


.signature-pad-wrapper {
  border: 1px solid #ced4da !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.btn-clear-signature {
  float: right;
}

.custom-btn {
  border-color: #88c656 !important;
}

.custom-btn:hover {
  background-color: #e0e0e0 !important;
}



.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
}

.textLayer>span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.textLayer ::-moz-selection {
  background: rgb(33, 150, 243);
}

.textLayer ::selection {
  background: rgb(33, 150, 243);
}



/* Control Display Layer */

.control-card {
  padding: 10px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

.control-card-description {
  font-size: 11px;
}

.control-card-danger {
  border-left: 2px solid red !important;
}

.control-card-warning {
  border-left: 2px solid #ffc107 !important;
}


.control-card-success {
  border-left: 2px solid green !important;
}

.control-card-badge {
  margin-right: 10px;
  border-radius: 2px !important;
  font-size: 10px !important;
}


@keyframes colorchange {
  0% {
    box-shadow: 0 0 20px #deebf5
  }

  50% {
    box-shadow: 0 0 20px #4e80a8
  }

  100% {
    box-shadow: 0 0 20px #5e9fd2;
  }
}

.color-change-animation {
  animation: colorchange 1.5s;
  -webkit-animation: colorchange 1.5s;
}

/* End Of Control Display Layer */


.signature-control {
  padding: 6px 12px 6px 12px !important;
  border-radius: 4px;
  border: 1px;
  line-height: 0 !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border-left: 5px solid $prince;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  border-radius: 2px !important;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  height: 100%;
}

.signature-control {
  background-color: $warning-100 !important;
  background-image: none;
  color: $text-color !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  &:hover {
    background-color: $warning-100 !important;
    border-left: 5px solid $prince;
  }

}

.signature-control-icon {
  display: none;
  margin-left: 5px;
}

.textbox-control {
  border: 1px solid #8bc34a !important;
  border-radius: 0px !important;
  background-color: #88c65626 !important;
  /* line-height: 0px;   */
}


.textbox-control-highlight {
  background-color: #fff6db !important;
}

.extraction-control {
  outline: #39f dashed 1px;
  background-color: rgba(194, 255, 169, 0.4);
}

.configurable-document-control {
  border: 1px solid #8bc34a !important;
  border-radius: 0px !important;
  line-height: 0px;
  height: auto !important;
  padding: 0px !important;
  width: 100%;
}

.textbox-control:focus {
  border-color: #8bc34a !important;
  box-shadow: 0 0 0 0.2rem rgb(207, 255, 209) !important;
}

.checkbox-control {
  border: 1px solid #8bc34a !important;
  border-radius: 2px !important;
}

.checkbox-control:focus {
  border-color: #8bc34a !important;
  box-shadow: 0 0 0 0.2rem rgb(207, 255, 209) !important;
}

/* Control Tooltip  */

.awesome-pdf-viewer-tooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted black;
  animation: shake .9s;
  animation-iteration-count: infinite;
  z-index: 100;
}

.awesome-pdf-viewer-tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: $warning-500;
  border-radius: 0px;
  padding: 2px 0;
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 110%;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: $text-color;  
}

.awesome-pdf-viewer-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent $warning-500 transparent transparent;
}

.awesome-pdf-viewer-tooltip>.tooltiptext.visible {
  visibility: visible;
}

.awesome-pdf-viewer-progress {
  height: 3px !important;
}

/*  End Of Control Tooltip */



/* ThumbnailView  */

.thumbnailSelectionRing {
  margin: auto;
  margin-top: 5px;
  border: 1px dashed #bcbcbc;
}

a:focus>.thumbnail>.thumbnailSelectionRing,
.thumbnail:hover>.thumbnailSelectionRing {
  outline: 0px solid transparent;
  border: 1px solid #0198fa;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tab-content>.active,
.nav-tabs>.active {
  background-color: #f8f8f8 !important;
}

.tab-content {
  height: 95%;
  overflow: auto;
}

.nav-tabs {
  overflow: hidden;
}


.nav-tabs .nav-link {
  border-radius: 0px !important;
}

.bookmark-panel-container {
  height: 100%;
}


.rwt__tabs[data-rwt-vertical="true"] {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.rwt__tabpanel {
  overflow: auto;
  width: 100%;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
}

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
}

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem .8rem !important;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #e8e8e8;
}

.rwt__tab[aria-selected="true"] {
  position: relative;
}

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute;
}

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #8BC34A;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #8BC34A;
}

















.outlineWithDeepNesting>.outlineItem,
.outlineItem>.outlineItems {
  margin-left: 15px;
}

/* .outlineWithDeepNesting > .outlineItem,
.outlineItem > .outlineItems {
  margin-right: 15px;
} */

.outlineItem>a,
.attachmentsItem>button {
  text-decoration: none;
  display: inline-block;
  min-width: 95%;
  min-width: calc(100% - 4px);
  /* Subtract the right padding (left, in RTL mode)
                                  of the container. */
  height: auto;
  margin-bottom: 1px;
  border-radius: 2px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: normal;
}

.attachmentsItem>button {
  border: 0 none;
  background: none;
  cursor: pointer;
  width: 100%;
}

html[dir='ltr'] .outlineItem>a {
  padding: 2px 0 5px 4px;
}

html[dir='ltr'] .attachmentsItem>button {
  padding: 2px 0 3px 7px;
  text-align: left;
}

html[dir='rtl'] .outlineItem>a {
  padding: 2px 4px 5px 0;
}

html[dir='rtl'] .attachmentsItem>button {
  padding: 2px 7px 3px 0;
  text-align: right;
}

.outlineItemToggler {
  position: relative;
  height: 0;
  width: 0;
  color: black;
  margin-left: -15px;
}

.outlineItemToggler::before {
  content: "\F107";
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: bolder;
}

.outlineItemToggler.outlineItemsHidden::before {
  content: "\F105";
  color: black;
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: bolder;
}

html[dir='rtl'] .outlineItemToggler.outlineItemsHidden::before {
  transform: scaleX(-1);
}

.outlineItemToggler.outlineItemsHidden~.outlineItems {
  display: none;
}

html[dir='ltr'] .outlineItemToggler {
  float: left;
}

html[dir='rtl'] .outlineItemToggler {
  float: right;
}

html[dir='ltr'] .outlineItemToggler::before {
  right: 4px;
}

html[dir='rtl'] .outlineItemToggler::before {
  left: 4px;
}

.outlineItemToggler:hover,
.outlineItemToggler:hover+a,
.outlineItemToggler:hover~.outlineItems,
.outlineItem>a:hover,
.attachmentsItem>button:hover {
  background-color: rgba(255, 255, 255, 0.02);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  border-radius: 2px;
  color: #6d6d6d;
}

.outlineItem.selected {
  background-color: rgba(255, 255, 255, 0.08);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  color: #6d6d6d;
}


/* Read Mode Styles*/


.read-mode-enabled {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.read-mode-toolbar>.read-mode-toolbar-items {
  cursor: pointer;
}

.read-mode-toolbar>.read-mode-toolbar-items>.toolbar-item-text-box {
  height: 25px !important;
  width: 30px !important;
  border: 1px solid #000000;
}

.read-mode-toolbar>.read-mode-toolbar-items>.divider {
  width: 0px !important;
  padding: 0px !important;
  margin: 8px 0 0 !important;
  height: 20px !important;
}

.read-mode-toolbar {
  width: 345px;
  height: 35px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000d9;
  color: #fff;
  cursor: move;
  z-index: 999;
  border-radius: 4px;
}

.icon-move {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  padding: 0 10px;
  background: rgba(100, 100, 100, 0.5);
  display: block;
  float: left;
}

.read-mode-toolbar a {
  color: #fff;
  display: block;
  float: left;
  font-weight: bold;
  /* height: 35px; */
  line-height: 35px;
  padding: 0 15px;
  transition-duration: 0.3s;
}

.read-mode-toolbar a:hover {
  background: rgba(100, 100, 100, 0.3);
  color: #8BC34A;
}


.download-icon {
  float: right;
  margin-top: 5%;
  cursor: pointer;
}

.table-control-container {
  border: 1px solid rgb(0 199 140);
  background-color: #f4fff763;
  position: absolute;
  z-index: 1;
  resize: both;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.table-control {
  border-collapse: collapse;
  border-spacing: 0px;
  position: absolute;
  background-color: #f3f3f3;
  z-index: 1;
  display: contents;
  user-select: none;
}

.table-control-footer,
.table-control-header {
  padding: 7px;
  display: flex;
}


.table-control>tbody>tr>td {
  border-bottom: 1px solid rgb(209, 209, 209);
  border-right: 1px solid rgb(209, 209, 209);
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  background-color: #f4fff76e;
}

/* .table-control > tbody > tr > td > div {
  resize: both;
  overflow: auto;
  margin: 0px;
  padding: 0px;
  border: 1px solid #e8e8e8;
  background-color: #f2ebff;
  width: auto;
  height: auto;
}  */

.table-control>tbody>tr:hover td {
  background-color: #e2ffe9;
  border: 1px dashed #b0b0b0;
  cursor: pointer;
}

.table-control-btn {
  background-color: #eaeaeac4 !important;
  border: 1px solid #d5d5d5 !important;
  cursor: move !important;
}

.control-property-box-container {
  border: 1px solid #dfdfdf;
  height: 100%;
  background-color: #007bff0a;
  overflow: auto;
}

.control-property-box-footer-btn {
  margin-right: 10px !important;
}

.control-property-box-table thead th {
  background-color: #77b7fb;
  padding: 3px !important;
  color: white;
  border: 1px solid #dee2e6;

}

.control-property-box-table tbody td {
  padding: 1px !important;
  border: 1px solid #dee2e6;
  width: 50%;
  font-size: 13px;
}

.control-property-box-table tbody td .input {
  width: 100%;
}

.control-property-box-table tbody td:nth-child(2) {
  text-align: center;
}

.control-property-box-table tbody td:nth-child(1) {
  padding: 1px 0px 5px 5px;
}

.textbox-control-btn {
  margin: 0px !important;
}

.textLayer .highlight {
  margin: -2px;
  padding: 1px;
  background-color: #6610f2 !important;
  border-radius: 4px;
}

.radio-button-group,
.checkbox-group {
  position: absolute;
  border: 1px solid rgb(0 199 140);
  background-color: #f4fff763;
  z-index: 1;
}

.radio-btn-container,
.checkbox-btn-container {
  position: absolute;
  user-select: none;
  z-index: 2;
}

.radio-btn-container input {
  cursor: move;
}

.checkbox-btn-container input {
  cursor: move;
}

.radio-group-control-footer,
.checkbox-group-control-footer {
  bottom: 3px;
  position: absolute;
}


.checkbox-control {
  border: 1px solid #8bc34a !important;
  border-radius: 2px !important;
}

.checkbox-control:focus {
  border-color: #8bc34a !important;
  box-shadow: 0 0 0 0.2rem rgb(207, 255, 209) !important;
}





.annotationLayer section {
  position: absolute;
}

.annotationLayer .linkAnnotation>a,
.annotationLayer .buttonWidgetAnnotation.pushButton>a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer .linkAnnotation>a:hover,
.annotationLayer .buttonWidgetAnnotation.pushButton>a:hover {
  opacity: 0.2;
  background: rgba(255, 255, 0, 1);
  box-shadow: 0px 2px 10px rgba(255, 255, 0, 1);
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
}

.annotationLayer .textWidgetAnnotation input,
.annotationLayer .textWidgetAnnotation textarea,
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  margin: 0;
  padding: 0 3px;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none;
}

.annotationLayer .textWidgetAnnotation input[disabled],
.annotationLayer .textWidgetAnnotation textarea[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
.annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation input:hover,
.annotationLayer .textWidgetAnnotation textarea:hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}

.annotationLayer .textWidgetAnnotation input:focus,
.annotationLayer .textWidgetAnnotation textarea:focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 1px solid transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: rgba(0, 0, 0, 1);
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 115%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}

.annotationLayer .popupWrapper {
  position: absolute;
  width: 20em;
}

.annotationLayer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: rgba(255, 255, 153, 1);
  box-shadow: 0px 2px 5px rgba(136, 136, 136, 1);
  border-radius: 2px;
  padding: 6px;
  margin-left: 5px;
  cursor: pointer;
  font: message-box;
  font-size: 9px;
  word-wrap: break-word;
}

.annotationLayer .popup>* {
  font-size: 9px;
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popup span {
  display: inline-block;
  margin-left: 5px;
}

.annotationLayer .popup p {
  border-top: 1px solid rgba(51, 51, 51, 1);
  margin-top: 2px;
  padding-top: 2px;
}

.annotationLayer .highlightAnnotation,
.annotationLayer .underlineAnnotation,
.annotationLayer .squigglyAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .freeTextAnnotation,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .squareAnnotation svg rect,
.annotationLayer .circleAnnotation svg ellipse,
.annotationLayer .polylineAnnotation svg polyline,
.annotationLayer .polygonAnnotation svg polygon,
.annotationLayer .caretAnnotation,
.annotationLayer .inkAnnotation svg polyline,
.annotationLayer .stampAnnotation,
.annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.hidden {
  display: none;
}

#page-bookmark-view>.outlineItem>a {
  display: inline-block;
  font: normal normal normal 13px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#page-bookmark-view>.outlineItem>a:before {
  content: "\F02E";
  padding-right: 10px;
  color: #72b6ff;
}

.outlineItem {
  margin-top: 5px;
}

.pdf-viewer-control-selection-tool {
  background: rgba(46, 115, 252, 0.11);
  border-radius: 0.1em;
  border: 2px solid rgba(98, 155, 255, 0.81);
  z-index: 4;
}


.page>.form-control {
  padding: 0px !important;
}

.awesome-pdf-viewer-radio-button {
  opacity: 0;
  position: absolute;
  top: 4px;
  width: 22px;
  height: 20px;
  z-index: 1;
}

.awesome-pdf-viewer-radio-button-label {
  position: relative;
}

.awesome-pdf-viewer-radio-button+.awesome-pdf-viewer-radio-button-label {
  background: #fff;
  border: 2px solid #8bc34a;
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
  margin-right: 0px;
  text-align: center;
}

.awesome-pdf-viewer-radio-button+.awesome-pdf-viewer-radio-button-label:before {
  border-radius: 0%;
}

.awesome-pdf-viewer-radio-button:checked+.awesome-pdf-viewer-radio-button-label:before {
  content: "\F00C";
  font-family: 'FontAwesome';
  color: #8bc34a;
  position: relative;
  top: -5px;
}

.awesome-pdf-viewer-radio-button:checked[data-color="#0973BA"]+.awesome-pdf-viewer-radio-button-label[data-color="#0973BA"]:before {
  color: #0973BA;
}

.awesome-pdf-viewer-radio-button:checked[data-color="#CC008D"]+.awesome-pdf-viewer-radio-button-label[data-color="#CC008D"]:before {
  color: #CC008D;
}

.file-node-selected {
  background: rgba(51, 51, 204, 0.1);
}
.rct-node-icon {
  color: $black;
}

/*SHEPHERD START*/
.shepherd-button {
  background-color: $warning-500 !important;
  border: 0;
  border-radius: 3px;
  color: hsla(0, 0%, 100%, 1);
  cursor: pointer;
  margin-right: .5rem;
  transition: all .5s ease;
  height: 100%;
  width: 100%;
  padding: 0px !important;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: $text-color; 

  &:active {
    transform: unset;
  }
}

.shepherd-element[data-popper-placement=left] .shepherd-footer button.shepherd-button {

  &:after {
    right: 0;
    top: 0;
    margin-left: 15px;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #d69f38;
  }
  &:before {
    display: none;
  }
}

.shepherd-element[data-popper-placement=right] .shepherd-footer button.shepherd-button {
  &:before {
    left: 1px;
    top: 0;
    margin-left: 0;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #d69f38;
  }
  &:after {
    display: none;
  }
}

.shepherd-element[data-popper-placement=right] {
  .shepherd-button {
    margin-left: 20px;
  }
}

.shepherd-button:focus {
  outline: none !important;
}

.shepherd-button:disabled {
  cursor: not-allowed
}

.shepherd-arrow,
.shepherd-header,
.shepherd-arrow:before {
  display: none !important;
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: initial;
  justify-content: flex-end;
  padding: 0 .1rem .1rem;
  height: 100%;
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
  display: inline-block;
}

.shepherd-element {
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s;
  z-index: 10 !important;
  width: auto !important;
  transform: translate(-388px, 231px);

}

.shepherd-enabled.shepherd-element {
  opacity: 1;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  transition: none;
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  fill-rule: evenodd;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all
}

.shepherd-element-right {
  margin-left: 18px !important;
}

.shepherd-element-left {
  margin-right: 18px !important;
}

.datepicker-control {
  z-index: auto;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.datepicker-control-input {
  height: 100%;
  width: 100%;
  display: flex !important;
}


.shepherd-content {
  height: 40px;
  width: 50px;
  margin-right: 19px;
}



input.checkbox-control.form-control:checked{
  background-color: $accent !important;
  &:after {
    position: absolute;
    content: "✔";
    top: 0;
    font-size: 10px;
    left: 2px;
    color: white;
  }
}

div[data-shepherd-step-id="btnFinish"] .shepherd-content {
  width: 49px;
  height: 45px;
  position: relative;
  bottom: 67px;
  right: -150%;
}

div[data-shepherd-step-id="btnFinish"] .shepherd-button:after {
    transform: rotate(90deg);
    position: absolute;
    top: 32px !important;
    left: -3px;
    border-top: 25px solid transparent !important;
    border-bottom: 26px solid transparent !important;
    border-left: 25px solid #d69f38 !important;
    border-radius: 3px;
}

@media (min-width: 481px) {
  div[data-shepherd-step-id=btnFinish] .shepherd-content button {
    font-size: 14px;
  }
}


@media (max-width:481px) {

  .shepherd-content {
    height: 20px !important;
    width: 33px !important;
    font-size: xx-small !important;
  }

  .shepherd-footer .shepherd-button:last-child {
    margin-right: 0;
    display: inline-block;
    text-transform: uppercase;
  }

  .shepherd-footer :after {
    right: 11px !important;
    top: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent !important;
    border-bottom: 11px solid transparent !important;
    border-left: 10px solid #d69f38 !important;
}

  .shepherd-element-left button.shepherd-button:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 96%;
    top: 0px;
    border: 10px solid transparent !important;
    border-left: 12px solid #FF9800 !important;
  }

  .shepherd-element-right button.shepherd-button:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: 94%;
    top: 0px;
    border: 10px solid transparent !important;
    border-right: 12px solid #FF9800 !important;
  }

  div[data-shepherd-step-id="btnFinish"] .shepherd-content {
    width: 40px !important;
    height: 40px !important;
    position: relative;
    bottom: 60px !important;
    left:47vw !important;
  }
  div[data-shepherd-step-id="btnFinish"] .shepherd-button:after {
    transform: rotate(90deg);
    position: absolute;
    top: 28px !important;
    left: -5px !important;
    border-top: 20px solid transparent !important;
    border-bottom: 21px solid transparent !important;
    border-left: 20px solid #d69f38 !important;
    border-radius: 3px;
  }

  .awesome-pdf-viewer-tooltip .tooltiptext {
    visibility: hidden;
    width: 61px;
    height: 16px;
    background-color: $warning-500;
    border-radius: 3px;
    padding: 2px 0;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 110%;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: $text-color;  
  }
  
  .awesome-pdf-viewer-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -8px;
    border-width: 8px;
    margin-right: -2px;
    border-style: solid;
    border-color: transparent $warning-500 transparent transparent;
  }

  .react-checkbox-tree{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rct-node-clickable{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media (orientation: landscape) and (max-width: 960px) { 
  div.shepherd-content {
    margin-right: 13px;
  }
  div.shepherd-element[data-popper-placement=left] .shepherd-footer button.shepherd-button:after {
    right: 0;
  }
}

@media (max-width:960px) {

  .shepherd-content {
    height: 30px;
    width: 40px;
    font-size: small;
    margin-right: 10px;
  }

  .shepherd-footer .shepherd-button:last-child {
    margin-right: 0;
    display: inline-block;
    text-transform: uppercase;
  }

  .shepherd-element[data-popper-placement=right] {
    .shepherd-content {
      margin-right: 0;
      margin-left: 10px;
    }
    .shepherd-button {
      margin-left: 4px;
    }
  }

  .shepherd-element[data-popper-placement=left] .shepherd-footer button.shepherd-button {
    &:after {
      right: -3px;
      top: 0;
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 15px solid #d69f38;
      position: absolute;
    }
    &:before {
      display: none;
    }
  }

  .shepherd-element[data-popper-placement=right] .shepherd-footer button.shepherd-button {
    &:before {
      right: -4px;
      top: 0;
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 15px solid #d69f38;
      position: absolute;
      z-index: -1;
    }
    &:after {
      display: none;
    }
  }

  .awesome-pdf-viewer-tooltip .tooltiptext {
    visibility: hidden;
    width: 61px;
    height: 16px;
    background-color: $warning-500;
    border-radius: 3px;
    padding: 2px 0;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 110%;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: $text-color;  
  }
  
  .awesome-pdf-viewer-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -8px;
    border-width: 8px;
    margin-right: -2px;
    border-style: solid;
    border-color: transparent $warning-500 transparent transparent;
  }

  .react-checkbox-tree{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rct-node-clickable{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

@media (min-width: 480px) and (max-width: 600px) {
  div[data-shepherd-step-id="btnFinish"] .shepherd-content {
    width: 49px;
    height: 45px;
    position: relative;
    bottom: 67px;
    left: 47vw;
  }
}

@media (max-width: 481px) {
  .shepherd-element[data-popper-placement=right] {
    .shepherd-content {
      margin-right: 0;
      margin-left: 10px;
    }
    .shepherd-button {
      margin-left: 0;
    }
  }

  .shepherd-element[data-popper-placement=left] .shepherd-footer button.shepherd-button {
    &:after {
      right: 0;
      top: 0;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 10px solid #d69f38;
      left: 17px;
      position: absolute;
    }
    &:before {
      display: none;
    }
  }

  .shepherd-element[data-popper-placement=right] .shepherd-footer button.shepherd-button {
    &:before {
      right: 0;
      top: 0;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 10px solid #d69f38;
      left: 2px;
      position: absolute;
      z-index: -1;
    }
    &:after {
      display: none;
    }
  }
}

/*SHEPHERD END*/

//previous div was getting scrolled up while loading, which was causing loader div(in e-sign page while navigating between sections) to look shorter than expected. Hence, added these lines to fix it.
.awesome-pdf-viewer-loader.visible {
  height: calc(100% + 10px) !important;
}



