@import "./variables.scss";

.title-h1 {
  font-family: $font-family;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
}

.title-h2 {
  font-family: $font-family;
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
}

.title-h3 {
  font-family: $font-family;
  font-size: 28px;
  font-weight: 300;
  line-height: 33px;
}

.title-h4 {
  font-family: $font-family;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.text-sapphire {
  color: $sapphire;
}