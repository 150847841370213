@import "../../../styles/variables.scss";
@import '../../../styles/mixins.scss';

.bookmark-row {
    height: 36px;
    border-bottom: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;

    &:hover {
        background-color: $gray-background;
    }

    .bookmark-item {
        display: flex;
        align-items: center;
        width: 90%;
        min-width: 0;

        label {
            font-size: 14px;
            padding-left: 10px;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            box-sizing: border-box;
            flex: 1;
        }

        .bookmark-icon {
            flex-shrink: 0;
            margin-right: 5px;
        }
    }
}
.file-name-sign{
    white-space: nowrap;
    overflow: hidden;
    display: table-cell;
    text-overflow: ellipsis;
    max-width: 200px;
}

.engagement-letter-accordion-item {
    padding: 0px;
    height: 75px !important;
    box-shadow: none !important;

   
    .engagement-letter-bookmark-row {
        padding: 6px 10px 6px 30px;
        border-bottom: none !important;

        &:hover {
            background-color: $prince-400;
        
        }
    }
    
    .engagement-letter-accordion-header {
        button {
            background-color: $white !important;
            &:focus {
                box-shadow: none;
            }
        }    
    }

    .accordion-body {
        padding: 0px;
    }
    .accordion-collapse {
        padding: 0px;
        height: 37px;
    }
}
.engagement-letter-accordion-item .not-collapsed-item {
    height: 100%;
}
.collapsed-item {
    height: 40px !important;
}
.header {
    .middle-section {
        width: 78% !important;
        max-width: 78% !important;
    }

    .right-section {
        max-width: 5% !important;
    }
}

.draggable-signature-control {
    width: 200px;
    max-width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    border: 1px solid #a6a9ac;
    border-left: 6px solid #542D87;
    background-color: #fbf5eb;
    padding: 3px 12px;
    position: absolute;
    z-index: 5;
    border-radius: 4px;
    cursor: move;
}

.remove-handle {
    width: 18px;
    position: absolute;
    height: 18px;
    top: -10px;
    padding: 1px 5px;
    background: #000;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-panel-container {
    padding: 10px;
}


/* Draw signature modal*/
.sign-modal {
    .sign-modal-header {
        h5 {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

        }
    }

    .sign-modal-body {
        padding: 0px;

        .sign-modal-tabs {
            display: flex;
            align-items: center;

            button {
                width: 50%;
                padding: 5px 0px !important;

                &::after {
                    border-bottom: 3px solid $sapphire !important;
                }
            }
        }

        .type-signature-container {
            padding: 10px 20px;
            height: 100%;

            label {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;

            }

            input {
                font-size: 16px;
            }

            .signature-pad-wrapper {
                height: 170px;

                canvas {
                    padding-top: 40px;
                    padding-bottom: 0px;
                    height: 200px;
                    font-size: 66px;
                    font-weight: 900;
                    line-height: 99px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }

        .draw-signature-form-group {
            padding: 10px 20px;

        }
    }

    .sign-modal-footer {
        padding: 10px 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            width: 100px;
            height: 38px;
            padding: 6px, 12px, 6px, 12px;
            border-radius: 2px;
            border: 1px;
            gap: 8px;

            &:nth-child(1) {
                background-color: $gray-background !important;
            }

            &:nth-child(2) {
                background-color: $sapphire !important;
                color: white !important;
            }
        }
    }
}

@media screen and (max-width: $mobile-max-width) {
    .sign-modal .sign-modal-footer.modal-footer {
        display: flex;
        justify-content: space-between;
        button {
            width: 49%;
            height: 48px;
            margin: 0;
            font-size: 20px !important;
        }
    }
    .draggable-signature-control {
        max-width: 35%;
    }
}
// ADDING Z-INDEX FOR DATE PICKER
.react-datepicker__tab-loop .react-datepicker-popper {
    z-index: 100;
}