$primaryColor: #fff;
$font-family: "Mulish", sans-serif;
$body-black: #212529;
$theme-accent: #88c656;
$theme-Sapphire: #0973ba;

$Sapphire-100: #e6f1f8;
$Sapphire-500: #6f42c1;
$Sapphire-600: #075c95;

$Shamrock-100: #f0f4ec;
$Shamrock-700: #47682d;

$Orange-100: #fbf0e7;
$Orange-600: #a9570f;