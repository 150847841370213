.drawer {
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    background-color: $sapphire-600;
    transition: .3s ease-in-out;
    z-index: 999;

    &.open {
        left: 0;
    }

    &-backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: 0;
        background-color: $backdrop-bg;
        z-index: 998;
        transition: .125s ease-in-out;
        opacity: 0;

        &.show {
            left: 0;
            opacity: 1;
        }
    }

    &-body {
        padding: 15px 0;
        color: $white;
    }
}