/*Custom radio button start*/
.magic-checkbox + label,
.magic-radio + label {
  font-weight: normal !important;
}

@keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }

  to {
    border-color: #3e97eb;
  }
}

.magic-radio,
.magic-radio-sm,
.magic-checkbox,
.magic-checkbox-sm {
  position: absolute;
  display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled],
.magic-radio-sm[disabled],
.magic-checkbox-sm[disabled] {
  cursor: not-allowed;
}

.magic-radio + label,
.magic-checkbox + label,
.magic-radio-sm + label,
.magic-checkbox-sm + label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
  vertical-align: middle;
}

.magic-radio-sm + label,
.magic-checkbox-sm + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before,
.magic-radio-sm + label:hover:before,
.magic-checkbox-sm + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio + label:before,
.magic-checkbox + label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  border: 1px solid #9d9d9d;
}

.magic-radio-sm + label:before,
.magic-checkbox-sm + label:before {
  position: absolute;
  top: -5px;
  left: -1px;
  display: inline-block;
  width: 15px;
  height: 15px;
  content: "";
  border: 1px solid #9d9d9d;
}

.checkbox-padding .magic-radio-sm + label:before,
.checkbox-padding .magic-checkbox-sm + label:before {
  top: -9px;
}

.magic-radio + label:after,
.magic-checkbox + label:after,
.magic-radio-sm + label:after,
.magic-checkbox-sm + label:after {
  position: absolute;
  display: none;
  content: "";
}

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label,
.magic-radio-sm[disabled] + label,
.magic-checkbox-sm[disabled] + label {
  cursor: not-allowed;
  color: #e4e4e4;
}

.magic-radio[disabled] + label:hover,
.magic-radio[disabled] + label:before,
.magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after,
.magic-radio-sm[disabled] + label:hover,
.magic-radio-sm[disabled] + label:before,
.magic-radio-sm[disabled] + label:after,
.magic-checkbox-sm[disabled] + label:hover,
.magic-checkbox-sm[disabled] + label:before,
.magic-checkbox-sm[disabled] + label:after {
  cursor: not-allowed;
}

.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before,
.magic-radio-sm[disabled] + label:hover:before,
.magic-checkbox-sm[disabled] + label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}

.magic-radio[disabled] + label:before,
.magic-checkbox[disabled] + label:before,
.magic-radio-sm[disabled] + label:before,
.magic-checkbox-sm[disabled] + label:before {
  border-color: #e4e4e4;
}

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before,
.magic-radio-sm:checked + label:before,
.magic-checkbox-sm:checked + label:before {
  animation-name: none;
}

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after,
.magic-radio-sm:checked + label:after,
.magic-checkbox-sm:checked + label:after {
  display: block;
}

.magic-radio + label:before,
.magic-radio-sm + label:before {
  border-radius: 50%;
}

.magic-radio + label:after,
.magic-radio-sm + label:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3e97eb;
}

.magic-radio:checked + label:before,
.magic-radio-sm:checked + label:before {
  border: 1px solid #3e97eb;
}

.magic-radio:checked[disabled] + label:before,
.magic-radio-sm:checked[disabled] + label:before {
  border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled] + label:after,
.magic-radio-sm:checked[disabled] + label:after {
  background: #c9e2f9;
}

.magic-checkbox + label:before,
.magic-checkbox-sm + label:before {
  border-radius: 1px;
}

.magic-checkbox + label:after {
  top: 2px;
  left: 7px;
  box-sizing: border-box;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox-indeterminate + label:after {
  top: 4px !important;
  left: 9px !important;
  width: 0px !important;
  height: 12px !important;
  transform: rotate(90deg) !important;
}

.magic-checkbox-sm + label:after {
  top: -5px;
  left: 3px;
  box-sizing: border-box;
  width: 6px;
  height: 11px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox-sm-indeterminate + label:after {
  top: -3px !important;
  left: 5px !important;
  width: 0px !important;
  height: 10px !important;
  transform: rotate(90deg) !important;
}

.magic-checkbox:checked + label:before,
.magic-checkbox-sm:checked + label:before {
  border: #3e97eb;
  background: #3e97eb;
}

.magic-checkbox:checked[disabled] + label:before,
.magic-checkbox-sm:checked[disabled] + label:before {
  border: #c9e2f9;
  background: #c9e2f9;
}

/*Custom radio button end*/

.custom-curve-check-box {
  padding-left: 5px;
  margin-bottom: 1px;
}

.custom-curve-check-box.SignatureArchivecheckbox,
.custom-curve-check-box.SignatureRecyclecheckbox {
  margin-top: 1px;
}

.custom-curve-check-box .magic-checkbox + label:before,
.custom-curve-check-box .magic-checkbox-sm + label:before {
  height: 16px;
  width: 16px;
  border-radius: 4px;
}

.custom-curve-check-box .magic-checkbox-sm + label:after,
.custom-curve-check-box .magic-checkbox + label:after {
  top: -2px !important;
  left: 4px !important;
  height: 8px !important;
  border-width: 2.5px;
}

.custom-curve-check-box .magic-checkbox-sm-indeterminate + label:after {
  top: -1px !important;
  left: 6.1px !important;
  height: 8px !important;
}

.custom-curve-check-box .magic-checkbox-sm:checked + label:before,
.custom-curve-check-box .magic-checkbox:checked + label:before {
  background: #0973ba;
}

.custom-column-filter-modal .custom-curve-check-box {
  margin-top: 1px;
}

.custom-column-filter-modal .custom-curve-check-box .form-check {
  padding-left: unset;
}

.custom-column-filter-modal .custom-curve-check-box .magic-checkbox:checked[disabled] + label:before {
  background: #84b9dc !important;
}

.custom-column-filter-modal .custom-curve-check-box .magic-checkbox + label:after {
  top: 3px !important;
  left: 5px !important;
}
.row-selected {
  background-color: #caedff !important;
}
.saved-message .custom-curve-check-box .magic-checkbox + label:after,
.header-text .custom-curve-check-box .magic-checkbox + label:after {
  top: 5px !important;
  left: 5px !important;
}
.display-checkbox {
    display: inline-block!important;
}