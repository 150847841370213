.loading-container{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  .dot-spin-loader {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
        12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0),
        -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin-loader 1.5s infinite linear;
}

@keyframes dot-spin-loader {
    0%,
    100% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656,
            12.727926px 12.727926px 0 0 #88c656, 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #88c656,
            -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }
    75% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #88c656,
            -12.727926px -12.727926px 0 0 #88c656;
    }
    87.5% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 -5px rgba(152, 128, 255, 0),
            12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.727926px -12.727926px 0 0 #88c656;
    }
}