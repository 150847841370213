@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.landing-page {
    &-container {
        height: calc(100% - 110px);
        display: flex;
    }

    &-side-bar {
        background-color: $sapphire-600;
        height: 100%;
        width: 350px;
        color: $white;
        font-family: $font-family;
        font-size: $font-size-base;
        padding: 20px 50px;
        position: relative;
        padding-top: 0;

        p {
            line-height: 21px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        .vertical-steps-container {
            margin-top: 40px;
            height: calc(100% - 240px);
            position: relative;

            .scroll-wrapper {
                padding: 10px 0;
                overflow: auto;
                height: 100%;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                height: 20px;
                width: 100%;
                left: 0;
                background-image: linear-gradient(to bottom, $sapphire-600, transparent);
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                height: 20px;
                width: 100%;
                left: 0;
                background-image: linear-gradient(to top, $sapphire-600, transparent);
            }

            .step {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .count {
                    @include step-bullet;
                    margin-right: 10px;
                }

                &.done {
                    .count {
                        background-color: $accent;
                        border-color: $accent;
                    }

                    .caption {
                        color: $sapphire-300;
                    }

                    &:last-child {
                        margin-bottom: 36px;
                    }
                }

                .caption-container {
                    position: relative;

                    .action-button {
                        background-color: $navy;
                        border: 1px solid $navy;
                        color: $white;
                        border-radius: 2px;
                        font-size: $font-size-sm;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: -40px;
                        padding: 4px 8px;
                        left: 8px;
                    }
                }
            }

            .vertical-line {
                width: 2px;
                height: 36px;
                background-color: $white;
                margin: 10px 0 10px 15px;
            }
        }

        .accent-button {
            margin: 40px 0 0;
            height: 48px;

            &.hide {
                height: 0;
                padding: 0;
                overflow: hidden;
                margin: 20px 0;
            }
        }

        .support-section {
            background-color: $sapphire-600;
            text-align: center;
            padding: 20px;
            position: relative;

            .contact-popup {
                display: inline-block;
            }
        }
    }

    &-steps-container {
        overflow: auto;
        height: calc(100% - 73px);
        padding-top: 20px;
    }

    &-content {
        height: 100%;
        width: calc(100% - 350px);
        padding: 0 50px 20px 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        overflow: auto;

        .greetings {
            h2 {
                font-size: 32px;
                font-weight: 300;
                margin-bottom: 20px;
                display: flex;
                span:nth-child(1) {
                    color: $sapphire;
                    padding-right: 10px;
                }
                span:nth-child(2) {
                    max-width: 90%;
                }
            }
        }

        .message-section {
            height: calc(100% - 294px);
            width: 100%;

            .content {
                background-color: $background-color-gray;
                padding: 15px;
                max-height: calc(100% - 30px);
                overflow-y: scroll;
                min-width: 150px;
            }
        }
    }
}

.tag {
    margin-top: 20px;
    padding: 3px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-sm;
    background-color: #{$sapphire-300}80;
    border-radius: 5px;

    &.done {
        background-color: #{$accent}80;
    }
}

div.mobile-view{
    .landing-page-container {
        height: calc(100% - 80px);

        .landing-page-content {
            width: 100%;
            justify-content: flex-start;
            overflow-y: auto;
            padding: 30px;
            align-items: center;

            button {
                min-height: 48px;
                font-size: 18px;
                font-weight: 400;
            }

            .greetings {
                flex-direction: column-reverse;

                h2 {
                    font-size: 28px;
                }
            }

            .accent-button {
                height: 48px;
                font-weight: 700;
            }

            .header-step-container {
                width: 165px;
                margin-bottom: 30px;
                position: relative;

                .step-wrapper {
                    width: 65px;
                    color: $sapphire-600;

                    .step {
                        border-color: $sapphire-600;
                        color: $sapphire-600;
                    }

                    .step-name{
                        text-align: center;
                        font-size: 12px;
                    }

                    &.done {
                        .step {
                            border-color: $accent;
                        }
                    }
                }

                .horizontal-line {
                    margin-bottom: 25px;
                    position: absolute;
                    width: 47px;
                    height: 2px;
                    left: 50px;
                    top: 10px;

                    & + .step-wrapper {
                        margin-left: 40px;
                    }

                }
            }
        }

        .tag {
            color: $sapphire-600;

            &.done {
                background-color: #F6FBF2;
                color: $shamrock;
            }
        }
    }
}

@media screen and (min-width: $mobile-max-width) and (max-width: 1024px) {
    .landing-page-container {
        .landing-page-content {
            .greetings {
                .welcome-title {
                    display: flex;
                    flex-direction: column;

                    .landing-page-welcome {
                        font-size: 24px;
                        margin-bottom: 4px;
                    }

                    .landing-page-name {
                        font-size: 3vw;
                        white-space: normal;
                        overflow: visible;
                        text-overflow: unset;
                    }
                }
                .landing-page-subtext {
                    font-size: 24px;
                }
            }
            
        }
    }
}