@import '../../../styles/variables.scss';

.FilesDragAndDrop {
  position: relative;
  width: 100%;
  height: 100%;

  .FilesDragAndDrop__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: #e7e7e7;
    color: #7f8e99;
    font-size: 24px;
    font-family: Tahoma, sans-serif;
    opacity: 1;
    text-align: center;

  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    cursor: default;
  }
}

.file-upload-container {
  min-width: 200px;
  height: 263px;
  box-sizing: border-box;
  border: 1px dashed $sapphire;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 5%;
  background-color: $navy-100;

  p{
    text-align: center;
  }
  .file-warn-label {
    margin: 16px 10px 5px 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: $gray-600;
  }
  .choose-file-button{
    width: 160px;
    margin: 10px;
    width: 150px;
    height: 38px;
    padding: 6px, 12px, 6px, 12px;
    border-radius: 20px;
    gap: 8px;

  }
}

@media screen and (max-width: $mobile-max-width) {
  .file-upload-container {
      .choose-file-button {
        height: 48px;
      }
  }  
}