@import "../../styles/variables.scss";
@import "../../styles/branding.scss";


.link-lock-page-continer {
  height: 100%;

  .link-lock-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // TODO: Remove this when the page is ready
    height: calc(100% - 110px);

    .link-lock-text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5px;

      .link-lock-text {
        font-family: $font-family;
        font-size: $font-size-l;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        white-space: pre;
        margin-top: 30px;
      }

      .highlight {
        color: $sapphire;
      }
    }

    .go-home-button {
      width: 250px;
      height: 38px;
      padding: 8px 16px;
      border-radius: 4px;
      margin-top: 20px;
    }
  }
}

div.mobile-view {

  .link-lock-page {
    .link-lock-text-container {
      .second-sentence-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .link-lock-text {
        font-size: $font-size-base;
        margin-top: 0px;
      }
    }
    .go-home-button {
      height: 48px;
      width: 200px;
    }
  }
}

.header-placeholder {
  height: 80px;
  background-color: $sapphire-600;
}

.invalid-page.link-lock-page-continer {
  .link-lock-page {
    padding: 20px;
  }
}