.tablePagination {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-top: 12px;
}

.tablePagination .showPageNumber {
  color: $theme-Sapphire;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0px;
  margin-right: 0.5rem;
}

.tablePagination .showPageNumber li {
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.tablePagination .showPageNumber span {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablePagination .showPageNumber svg {
  width: 15px;
}

.tablePagination .showPageNumber li.currentPageNumber {
  background-color: $theme-Sapphire;
  color: white;
  font-weight: normal;
  padding: 3px 9px !important;
  cursor: default;
}

.tablePagination .showPageNumber li.paginationActionDisabled {
  color: #c4c6c8;
  background-color: #f0f1f1;
  cursor: not-allowed;
}

.tablePagination li {
  padding: 8px;
}

.tablePagination .configurePagination {
  color: $gray-600;
  white-space: nowrap;
}
.tablePagination .configurePagination #pageSize {
  border: 1px solid $gray-400;
  border-radius: 3.5px;
  padding: 4px 8px;
  outline: none;
  color: $gray-600;
  cursor: pointer;
  background-color: #ffffff;
}

.tablePagination .configurePagination #pageSize option {
  color: $gray-600;
}

.tablePagination .configurePagination .paginationActionDisabled {
  color: #c4c6c8 !important;
  background-color: #f0f1f1 !important;
  cursor: not-allowed !important;
  height: 100%;
  opacity: unset;
}

.tablePagination .configurePagination #goToPage {
  border: 1px solid $gray-400;
  border-radius: 3.2px;
  outline: none;
  padding: 4px 8px;
  width: 55px;
  color: $gray-600;
  text-align: center;
  height: 100%;
}
