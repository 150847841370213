/* These utilities are same like bootstrap utilities with better namings. this is to avoid problems in bootstrap version migrations*/

$space-values: 0,
    5,
    10,
    15,
    20,
    25;

@each $value in $space-values {

    // MARGIN

    .margin-#{$value}-px {
        margin: $value + px !important;
    }

    .margin-top-#{$value}-px {
        margin-top: $value + px !important;
    }

    .margin-right-#{$value}-px {
        margin-right: $value + px !important;
    }

    .margin-bottom-#{$value}-px {
        margin-bottom: $value + px !important;
    }

    .margin-left-#{$value}-px {
        margin-left: $value + px !important;
    }

    .margin-top-bottom-#{$value}-px {
        margin-top: $value + px !important;
        margin-bottom: $value + px !important;
    }

    .margin-left-right-#{$value}-px {
        margin-left: $value + px !important;
        margin-right: $value + px !important;
    }

    // PADDING

    .padding-#{$value}-px {
        padding: $value + px !important;
    }

    .padding-top-#{$value}-px {
        padding-top: $value + px !important;
    }

    .padding-right-#{$value}-px {
        padding-right: $value + px !important;
    }

    .padding-bottom-#{$value}-px {
        padding-bottom: $value + px !important;
    }

    .padding-left-#{$value}-px {
        padding-left: $value + px !important;
    }

    .padding-top-bottom-#{$value}-px {
        padding-top: $value + px !important;
        padding-bottom: $value + px !important;
    }

    .padding-left-right-#{$value}-px {
        padding-left: $value + px !important;
        padding-right: $value + px !important;
    }
}