@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

// TODO: Move below styles
.attention-modal {
  .modal-body {
    p {

      &:nth-child(2) {
        margin-top: 20px;

      }
    }
  }
  .modal-footer {
    button {
      min-height: 38px;
    }
  }
}

.organizer-container {
  height: 100vh;
  .pdf-wrapper{
    height: calc(100% - 180px);
  }
  .right-panel-container {
    height: 100%;
    position: relative;
    width: 100%;

    .organizer-right-panel {
      height: calc(100% - 155px);
      h6 {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 10px !important;

      }

      textArea {
        font-size: 14px;
        width: 100%;
        height: 70px;
      }

      .notes-container {
        position: absolute;
        bottom: 2%;
        width: 90%;

        .notes-btn-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          span{
            cursor: pointer;
          }
          
        }
      }
      
      .upload-header-section {}

      .upload-btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 10px 0;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          padding-top: 10px;
          color: $gray-500;
        }
      }

      .uploaded-files-list-container {
        @include display-flex(column);
        justify-content: flex-start;
        border: 1px solid $gray-300;
        border-left: 0;
        border-right: 0;
        margin:10px 0px;
        padding: 10px 0px;
        height: calc(100% - 210px);
        overflow: auto;

        .uploaded-file-item {
          @include display-flex(row);
          justify-content: space-between;
          width: 100%;

          .file-name {
            max-width: 135px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
              
          }

          svg:nth-child(1) {
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .side-panel .form-group .Select {
    width: 120px;
    margin-left: 10px;
  }
}

.upload-organizer-file-modal {
  .modal-dialog {
    max-width: 100% !important;
  }
  .modal-content {
    width: 900px!important;
  }
  .upload-organizer-container {
    width: 100%;
    @include display-flex(column);
    align-items: flex-start;
    justify-content: flex-start;
  
    .info-container {
        margin-bottom: 10px;
        width: 100%;
        @include display-flex(row);
        justify-content: flex-start;
        align-items: flex-start;
      
        .alert-icon {
          margin-top: 4px;
        }
        .message {
            margin-bottom: 0px;
            padding-left: 8px;
            font-size: 14px;
            width: 90%;
        }
    }
  
    .content {
        width: 100%;
        margin-top: 20px;
        @include display-flex(row);
        align-items: flex-start;
        
        .document-upload {}
  
        .right-container {
            width: 100%;
            @include display-flex(column);
  
            .table-container {
                padding-left: 15px;
                padding-top: 10px;
            }
        }
    }
  
    .file-name {
        @include display-flex;
        justify-content: flex-start;
        align-items: center;
        gap: 3px;
  
        label {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 16px;
        }
    }
  
    .upload-progress-container {
      padding-right: 70px;
      box-sizing: border-box;

      .upload-progress-custom {
        height: 12px;
      }

      .upload-progress-custom .progress-bar {
        background-color: $shamrock;
      }
    }
  }
}

.button-pointer {
  border: none;
  background: transparent;
  padding: 4px;

  &.pointer-disabled  * {
    cursor: not-allowed !important;
  }

  &.pointer-enabled  * {
    cursor: pointer !important;
  }
}