.button-default {
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  text-align: center;
  border: none;
  padding: 6px 12px;
  min-width: 100px;
  cursor: pointer;
  line-height: normal;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $theme-Sapphire;
  background: white !important;
  border-radius: 2px !important;
  color: $theme-Sapphire !important;
  width: 98px;
  height: 31px;
  padding: 5px;
  font-size: 14px;

  &>svg {
    margin-right: 8px;
  }
}

.ss-btn-secondary {
  background-color: $gray-background !important;
  border: 1px solid $gray-400;
  color: $body-black !important;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: none;
  }
}

.ss-btn-primary {
  background-color: $theme-Sapphire !important;
  border: 1px solid $theme-Sapphire !important;
  color: white !important;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    border-color: $gray-200 !important;
    color: $gray-200 !important;
    opacity: 1;
    background-color: #F0F1F1 !important;
  }
}

.btn-inline-action-with-icon {
  width: 82px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  span {
    font-size: 14px;
    color: $navy;
  }

  &:hover {
    text-decoration: underline;
  }
}

.btn-inline-action {
  width: 48px;
  font-size: 14px;
  color: $navy;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.menu-seperator {
  border: 1px solid #21252940;
  height: 16px;
  width: 1px;
  margin-inline: 8px;
}

.icon-only-btn {
  width: 38px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid $gray-500 !important;
  background-color: white !important;

  &:disabled {
    background-color: $gray-100 !important;
  }
}

.btn-without-border {
  border: none;
  background-color: transparent;
  color: $theme-Sapphire;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    box-shadow: none;
  }
}
.accent-button {
  padding: 8px 16px;
  background-color: $accent;
  font-size: $font-size-md;
  color: $text-color;
  border-radius: 30px;
  border: none;
  width: 100%;
  font-weight: 600;
}


button.rounded-button { 
  border-radius:  20px;
}

.green-button {
  background-color: $green;
  border-color: $green;

  &:hover {
    background-color: darken($green, 10%);
    border-color: darken($green, 10%);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $gray-background;
    border-color: $gray-200;
    color: $gray-200;
  }
}

button.block {
  width: 100%;
}